import React, {useRef, useState, useEffect, useContext, useMemo} from 'react';
import { Dropdown, Input, Modal, Checkbox, message, Popover, Image } from 'antd';
import API from '@api/api.js';
import { useNavigate } from "react-router-dom";
import styles from './projectPicView.module.scss'
import PSAIModal from '@PSAIComponents/PSAIModal';
import { IMAGE_TYPE } from '@utils/CONST';
import Utils from '@utils/utils'

const ProjectPicView = (props) => {
    const {
        favorite, // 收藏
        favoriteList, // 收藏列表
        projectInfo,
        clickFn,
        showDetail,
        forceUpdate,
        isSelected,
        setSelectedPics,
        checked,
    } = props;

    // 是否重命名编辑中
    const [isNameEdit, setIsNameEdit] = useState(false);
    // 重命名的名字
    const [newName, setNewName] = useState(projectInfo?.projectName);
    const reNameRef = useRef();

    const deleteProject = () => {
        return API.deleteProject({
            projectId: projectInfo?.id
        }).then((res) => {
            if (res?.code === 0) {
                forceUpdate({})
                message.success('删除成功');
            } else {
                message.error('删除失败');
            }
        })       
    };

    const renameProject = () => {
        API.renameProeject({
            projectId: projectInfo?.id,
            newName: newName
        }).then((res) => {
            if (res?.code === 0) {
                forceUpdate({})
                message.success('修改成功');
            } else {
                setNewName(projectInfo?.projectName)
                message.error(res.message);
            }
        }).finally(() => {
            setIsNameEdit(false);
        })
    }

    const checkPicStatus = (checked) => {
        // 选中
        if (checked) {
            setSelectedPics((preVal) => ([
                ...preVal,
                projectInfo?.id,
            ]));
            return;
        }
        setSelectedPics((preVal) => {
            const index = preVal.indexOf(projectInfo?.id);
            if (index === -1) {
                return preVal;
            }
            preVal.splice(index, 1)
            return [...preVal];
        });
    }

    useEffect(() => {
        setNewName(projectInfo?.projectName)
    }, [projectInfo?.projectName])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (reNameRef?.current?.input && !reNameRef?.current?.input?.contains(event.target)) {
                setIsNameEdit(false);
            }
        }
    
        document.addEventListener('click', handleClickOutside);
    
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
      }, []);

    const onClickImg = () => {
        if (isSelected) return
        clickFn && clickFn()
    }

    const mainImage = useMemo(() => {
        if (projectInfo?.mainImage) {
            const list = projectInfo?.mainImage.split(',')
            return list[0]
        } else return ''
    }, [projectInfo?.mainImage])

    const onDelete = () => {
        PSAIModal.confirm({
            title: "删除项目",
            content: "删除后项目将无法找回，确定删除吗？",
            onOk: deleteProject
        })
    }

    return (
        <div className={styles.m_box_item_comp}>
            {
                favorite ?
                <div className={`${styles.m_box_item} ${styles.m_box_item_comp_item} ${styles.favoriteBox}`}>
                {
                    favoriteList.length > 0 ?
                    // <img className={styles.main_img} src={favoriteList?.[0].results?.[0].image+'?x-oss-process=style/thumbnail-width-500'} alt="封面" onClick={onClickImg} /> :
                    (
                        favoriteList?.[0].imageType == IMAGE_TYPE.COMMODITY_VIDEO || favoriteList?.[0].imageType == IMAGE_TYPE.MODEL_VIDEO ?
                        <video
                            className={styles.main_img}
                            onClick={onClickImg}
                            width={"100%"}
                            height={"100%"}
                            src={`${favoriteList?.[0].results?.[0].image}`}
                            disablePictureInPicture
                        ></video> :
                        <Image
                            className={styles.main_img}
                            onClick={onClickImg}
                            width={"100%"}
                            height={"100%"}
                            preview={false}
                            src={Utils.getImgUrlWithWebp(favoriteList?.[0].results?.[0].image, true)}
                        />
                    ) :
                    <div className={styles.empty_img}></div>
                }
                </div> :
                <div className={`${styles.m_box_item} ${styles.m_box_item_comp_item}`}>
                {
                    !isSelected &&
                    <i className={styles.del_btn} onClick={onDelete}></i>
                }
                {
                    !isSelected &&
                    <div className={styles.check_view} onClick={projectInfo.reviewStatus == 2?null: props.clickFn} >
                        查看合集
                    </div>
                }
                <Image
                    className={projectInfo.reviewStatus == 2? `${styles.main_img} ${styles.main_img_}` :styles.main_img}
                    onClick={projectInfo.reviewStatus == 2?null: onClickImg}
                    width={"100%"}
                    height={"100%"}
                    preview={false}
                    src={Utils.getImgUrlWithWebp(mainImage, true)}
                />
                {
                    isSelected &&
                    <div style={{position: 'absolute', width: '100%', height: '100%'}} onClick={() => checkPicStatus(!checked)}>
                        <Checkbox
                            className={styles.m_box_item_select}
                            checked={checked}
                        ></Checkbox >
                    </div>
                }
                </div>
            }
            
            {
                favorite ? 
                <div style={{marginTop: '0.1rem'}}>
                    <p
                        title={newName}
                        className={styles.item_title}
                    >
                        <i className={styles.favor_icon}></i><span>{newName}</span>
                    </p>
                </div> :
                <div style={{marginTop: '0.1rem'}}>
                {
                    isNameEdit &&
                    <Input
                        className={styles.item_input}
                        value={newName}
                        ref={reNameRef}
                        onPressEnter={() => renameProject()}
                        onBlur={() => renameProject()}
                        onChange={(e) => setNewName(e.target.value)}>
                    </Input>
                }
                {
                    !isNameEdit &&
                    <p
                        title={newName}
                        className={styles.item_title}
                    >
                        <span>{newName}</span>
                        {/* <img
                            onClick={ (e) => { e.stopPropagation();setIsNameEdit(true) } }
                            src={require('@assets/images/Edit_Normal.png')}
                            alt="重命名"
                        /> */}
                    </p>
                }
                {
                    showDetail.sortField == 'createdTime' ? 
                        <>
                            <p className={`${styles.item_title} ${styles.item_title_}`}>创建时间 {projectInfo?.createdTime}</p>
                        </> :
                        <>
                            <p className={`${styles.item_title} ${styles.item_title_}`}>修改时间 {projectInfo?.updatedTime}</p>
                        </>
                }
                </div>
            }
            {
                projectInfo.imageType == IMAGE_TYPE.COMMODITY_VIDEO || projectInfo.imageType == IMAGE_TYPE.MODEL_VIDEO ?
                <div className={styles.video_flag}></div> : ""
            }
        </div>
    )
}

export default ProjectPicView;
