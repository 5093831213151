import useResizeObserver from '@utils/useResizeObserver'
import { useContext, useEffect, useCallback, useState } from 'react'
import styles from './index.module.scss'
import Utils from '@utils/utils'
import ToolBox from '../../components/ToolBox/ToolBox';
import Crop from '@view/compoents/crop/crop';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { GenerateContext } from '@utils/GenerateContext';
import { Spin, Upload, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AppContext } from '@utils/AppContext';
import { PRIVATE_ABILITIES } from '@utils/CONST';

const VideoImage = (props) => {
    const [deskWidth, setDeskWidth] = useState(0)
    const [deskHeight, setDeskHeight] = useState(0)
    const [imgSize, setImgSize] = useState({ widt: 0, height: 0})
    const {setGlobalLoading, hasPrivateAbility} = useContext(AppContext)
    const {
        modelVideoFrontPic, setModelVideoFrontPic,
        modelVideoBackPic, setModelVideoBackPic,
    } = useContext(GenerateContext)

    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setDeskWidth(entry.contentRect.width)
            setDeskHeight(entry.contentRect.height)
        }
    })

    useEffect(() => {
        if (!(deskWidth * deskHeight)) return
        Utils.getImageDimensions(Utils.getImgUrlWithWebp(modelVideoFrontPic)).then(({width, height}) => {
            const size = Utils.getImgMaxSizeInContainer(
                width,
                height,
                deskWidth - 220,
                deskHeight
            )
            setImgSize({
                width: size.width,
                height: size.height
            })
        })  
    }, [deskWidth, deskHeight, modelVideoFrontPic])

    const reUpload = (file) => {
        props.onUpload(file)
    }
    const uploadHandle = async (file) => {
        const res = await props.onBeforeUpload(file, null, false)
        setGlobalLoading(false)
        if (!res) {
            return false
        } else {
            setModelVideoBackPic(res)
            return false
        }
    }
    const UploadBackImg = (
        <Upload
            accept='image/jpeg,image/png,image/webp,image/avif'
            listType="picture-card"
            beforeUpload={uploadHandle}
            className={styles.UploadBackImgContainer}
            showUploadList={false}
        >
            <div className={styles.UploadBackImg}>
                <PlusOutlined />
                <p>上传背面图片</p>
                <p>— 可选 —</p>
            </div>
        </Upload>
    )

    const uploadDebounceBackImg = useCallback(_.debounce(async (file, fileList) => {
        console.log('===uploadDebounce===')
        const res = await props.onBeforeUpload(file, null, false)
        setGlobalLoading(false)
        if (!res) {
            return false
        } else {
            setModelVideoBackPic(res)
            return false
        }
    }, 100), [])
    const handleDropBackImg = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            if (filesAndDirs.length == 0) return false
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounceBackImg(fileList[0], fileList)
            return false
        }
    }
    const uploadDebounceFrontImg = useCallback(_.debounce(async (file, fileList) => {
        console.log('===uploadDebounce===')
        props.onUpload(file)
    }, 100), [])
    const handleDropFrontImg = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            if (filesAndDirs.length == 0) return false
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounceFrontImg(fileList[0], fileList)
            return false
        }
    }

    return (
        <div className={styles.Container}>
            <div className={styles.DeskWrap} ref={targetRef}>
            {
                (imgSize.width * imgSize.height) ?
                <div className={styles.ImgDesk} >
                    <div className={styles.FrontImg} style={{ width: imgSize.width, height: imgSize.height }}>
                        <Upload.Dragger
                            accept={'image/jpeg,image/png,image/webp,image/avif'}
                            name="avatar"
                            showUploadList={false}
                            className={styles.UploadWrap}
                            maxCount={1}
                            beforeUpload={()=>false}
                            onDrop={handleDropFrontImg}
                        >
                            <div style={{cursor: "default"}} onClick={e => e.stopPropagation()}>
                                <Image
                                    preview={false}
                                    src={Utils.getImgUrlWithWebp(modelVideoFrontPic)}
                                    placeholder={
                                        <Image
                                            preview={false}
                                            src={Utils.getImgUrlWithWebp(modelVideoFrontPic, true, true)}
                                        />
                                    }
                                />
                                <img style={{position: 'absolute', right: 5, top: 5, cursor: 'pointer'}} onClick={() => {setModelVideoFrontPic();setModelVideoBackPic()}} src={require('@assets/images/incorrect (1).png')}/>
                            </div>
                        </Upload.Dragger>
                        <div className={styles.ToolBox}>
                            <ToolBox
                                mattingDisabled={true}
                                composeDisabled={true}
                                reUpload={reUpload}
                            />
                        </div>
                    </div>
                    <div className={styles.BackImg}>
                    {
                        modelVideoBackPic?
                        <Upload.Dragger
                            accept={'image/jpeg,image/png,image/webp,image/avif'}
                            name="avatar"
                            showUploadList={false}
                            className={styles.UploadWrap}
                            maxCount={1}
                            beforeUpload={()=>false}
                            onDrop={handleDropBackImg}
                        >
                            <div style={{position: "relative",cursor: "default"}} onClick={e => e.stopPropagation()}>
                                <img src={Utils.getImgUrlWithWebp(modelVideoBackPic)} />
                                <img style={{position: 'absolute', right: 5, top: 5, cursor: 'pointer'}} onClick={() => setModelVideoBackPic()} src={require('@assets/images/incorrect (1).png')}/>
                            </div>
                        </Upload.Dragger> :
                        hasPrivateAbility(PRIVATE_ABILITIES.VIDEO_TRYON_SUPPORT_BACK)?
                        UploadBackImg: null
                    }
                    </div>
                </div> :
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spin size="large"/>
                </div>
            }
            </div>
        </div>
    )
}

export default VideoImage