import React, {useRef, useState, useEffect, useContext, useMemo} from 'react';
import { Dropdown, Input, Modal, Checkbox, message, Popover, Image, Spin } from 'antd';
import API from '@api/api.js';
import { useNavigate } from "react-router-dom";
import TaskTools from '../taskTools/taskTools';
import styles from './picView.module.scss'
import { AppContext } from '@utils/AppContext';
import ShareModal from '../shareModal/shareModal';
import Utils from '@utils/utils'
import { IMAGE_TYPE } from "@utils/CONST"
import PSAIModal from '@PSAIComponents/PSAIModal';

const PicView = (props) => {
    const {
        isProEnv,
        setGlobalCommodityFilter,
        setGlobalModelFilter,
        forceUpdate,
        hdDownloadWithLoadingFunc,
    } = useContext(AppContext);

    const navigate = useNavigate();
    const isMounted = useRef(true) // 判断组件是否已销毁
    const taskInfoRef = useRef()
    const [downloadOpen, setDownloadOpen] = useState()
    
    const isHDPreview = useMemo(() => {
        return props.taskResult.hdImage
    }, [props.taskResult])

    const mainImage = useMemo(() => {
        return props.taskResult.hdImage || props.taskResult.image
    }, [props.taskResult])

    const reviewStatus = useMemo(() => {
        return props.taskResult.reviewStatus == 2
    }, [props.taskResult])

    const imageIsIllegal = useMemo(() => {
        // console.log('imageIsIllegal', props.taskResult)
        return /out-error-default\.png/.test(mainImage) || props.taskResult.reviewStatus == 2
    }, [mainImage, props.taskResult])

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    // 更换mote
    const onChangeModel = async () => {
        // 埋点
        Utils.useroplog(props.imageType, 'function', 'changeModel', {'taskId': props.taskId})
        try {
            if (!taskInfoRef.current) {
                const res = await API.getTaskInfo({ taskId: props.taskId })
                if (!res || res.code != 0 || !res.data) throw new Error(res?.message)
                taskInfoRef.current = res.data
                if (props.imageType == IMAGE_TYPE.CLOTHES) {
                    const modelInpaintId = res.data?.inpaint?.attr?.modelInpaintId
                    if (modelInpaintId) { // 获取原始背景
                        const res2 = await API.getInpaintPredictionById(modelInpaintId)
                        if (!res2 || res2.code != 0 || !res2.data) throw new Error(res2?.message)
                        const {mote_style, mote_bg_style, seed, sub_bgid} = res2.data.input
                        taskInfoRef.current.inpaint.input.mote_style = mote_style // 为了回模特
                        taskInfoRef.current.inpaint.input.bkg_style = mote_bg_style
                    }
                    // if (!modelInpaintId) {
                    //     message.warning('当前任务不支持此操作')
                    //     return false
                    // }
                }
            }
            const modelFilter = taskInfoRef.current.inpaint?.attr?.modelFilter
            if (modelFilter) setGlobalModelFilter(modelFilter)
            const commodityFilter = taskInfoRef.current.inpaint?.attr?.commodityFilter
            if (commodityFilter) setGlobalCommodityFilter(commodityFilter)
            const input = taskInfoRef.current.inpaint?.input
            props.changeModelCb(
                input?.mote_style,
                props.taskId,
            )
        } catch (err) {
            message.error(err.message)
        }
    }

    // 更换场景
    const onChangeBg = async () => {
        // 埋点
        Utils.useroplog(props.imageType, 'function', 'changeBackgroud', {'taskId': props.taskId})
        try {
            if (!taskInfoRef.current) {
                const res = await API.getTaskInfo({ taskId: props.taskId })
                if (!res || res.code != 0 || !res.data) throw new Error(res?.message)
                taskInfoRef.current = res.data
                if (props.imageType == IMAGE_TYPE.CLOTHES) {
                    const modelInpaintId = res.data?.inpaint?.attr?.modelInpaintId
                    if (modelInpaintId) { // 获取原始背景
                        const res2 = await API.getInpaintPredictionById(modelInpaintId)
                        if (!res2 || res2.code != 0 || !res2.data) throw new Error(res2?.message)
                        const {mote_style, mote_bg_style, seed, sub_bgid} = res2.data.input
                        taskInfoRef.current.inpaint.input.mote_style = mote_style
                        taskInfoRef.current.inpaint.input.bkg_style = mote_bg_style // 为了回显场景
                    }
                    // if (!modelInpaintId) {
                    //     message.warning('当前任务不支持此操作')
                    //     return false
                    // }
                }
            }
            const input = taskInfoRef.current.inpaint?.input
            props.changeBgCb(
                props.imageType == IMAGE_TYPE.CLOTHES ?
                    input?.bkg_style :
                    input?.mote_bg_style,
                props.taskId
            )
        } catch (err) {
            message.error(err.message)
        }
    }

    // 更换动作
    const onChangeAction = async () => {
        // 埋点
        Utils.useroplog(props.imageType, 'function', 'changeAction', {'taskId': props.taskId})
        // const res = await API.getTaskInfo({ taskId: props.taskId })
        // if (!res || res.code != 0 || !res.data) throw new Error(res?.message)
        props.changeActionCb(props.taskId)
    }

    // 去AI编辑
    const toAiEditor = async () => {
        try {
            let res = await API.commonDownload({taskId: props.taskId})
            if (res?.code != 0) throw new Error(res?.message)
            const imgPath = res.data
            // 埋点，记录AI 编辑的是什么类型的图
            sessionStorage.setItem('oplog_imageType', props.imageType)
            navigate(
                '/home/homemain/aiEdit',
                {
                    state: {
                        picUrl: imgPath,
                    }
                },
                sessionStorage.setItem('uploadImg2', 'uploadImg')
            )
        } catch (err) {
            message.warning(err.message)
        }
    }

    // 收藏
    const isLoading = useRef()
    const toBookmark = () => {
        if (isLoading.current) return
        isLoading.current = true
        API.bookmarkTask({
            taskId: props.taskId
        }).then(() => {
            if (props.bookmarked) message.success('已取消收藏')
            else message.success('已收藏')
            forceUpdate({})
        }).finally(() => {
            isLoading.current = false
        })
    }

    // 删除任务
    const hadnleDeleteTask = () => {
        return API.deleteTaskImage({
            taskId: props.taskId,
            imageId: props.imageId
        }).then((res) => {
            if (res?.code === 0) {
                forceUpdate({})
                message.success('删除成功');
            } else {
                message.error('删除失败');
            }
        })     
    };

    // 复刻
    const [replicaOpen, setReplicaOpen] = useState()
    const onReplica = () => {
        if (props.isSelected) return
        // 埋点
        Utils.useroplog(props.imageType, 'function', 'reprint', {'taskId': props.taskId})
        props.replicaFn && props.replicaFn()
        return
    }

    // 普通下载
    const commonDownloadFunc = async () => {
        try {
            setMenuOpen(false)
            let res = await API.commonDownload({taskId: props.taskId})
            if (res?.code != 0) throw new Error(res?.message)
            // console.log('commonDownload', res)
            const image = res.data
            Utils.downUrl(image, props.imageType)
            message.success('下载成功');
        } catch (err) {
            message.error(err.message)
        }
    }

    // 高清下载
    const hdDownloadFunc = async () => {
        try {
            setMenuOpen(false)
            const hdAvailable = isHDPreview
            if (!hdPayed) {
                PSAIModal.confirm({
                    title: `下载高清图将扣除您${hdCost}个虹豆`,
                    content: "该图片生成后进行下载操作无需再次扣点，您确认要下载吗？",
                    onOk: async () => {
                        if (props.imageType == IMAGE_TYPE.MODEL_VIDEO) {
                            hdDownloadWithLoadingFunc(props.taskId, props.imageId, props.imageType, props.topic).then(() => forceUpdate({}))
                        } else {
                            if (hdAvailable) {
                                const res = await API.hdDownload({taskId: props.taskId})
                                if (res?.code != 0) throw new Error(res?.message)
                                const image = res.data
                                Utils.downUrl(image, props.imageType)
                                message.success('下载成功')
                            } else {
                                hdDownloadWithLoadingFunc(props.taskId, props.imageId, props.imageType, props.topic).then(() => forceUpdate({}))
                            }
                        }
                    }
                })
            } else {
                const res = await API.hdDownload({taskId: props.taskId})
                if (res?.code != 0) throw new Error(res?.message)
                const image = res.data
                Utils.downUrl(image, props.imageType)
                message.success('下载成功')
            }          
        } catch (err) {
            message.error(err.message)
        }
    }

    const hdPayed = useMemo(() => {
        return props.taskResult.payed
    }, [props.taskResult])

    const hdCost = useMemo(() => {
        if (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO) return 40
        return 5
    }, [props.imageType])

    const downloadMenuContent = (
        <div className={`${styles.DownloadMenu} ${hdPayed && styles.hdPayed}`}>
            <div className={styles.MenuItem1} onClick={hdDownloadFunc}>
                <span>高清下载</span>
                {
                    !hdPayed &&
                    <div style={{display: 'flex',alignItems: 'baseline'}}>
                    <span>（消耗</span>
                    <img style={{width: '10px', height: '10px'}} src={require('@assets/images/arcbean2.png')}/>
                    <span>{hdCost}）</span>
                    </div>
                }
            </div>
            <div className={styles.MenuItem1} onClick={commonDownloadFunc}>
                <span>普通下载</span>
            </div>
        </div>
    )

    const dropdownMenus = useMemo(() => {
        if (!props.menuOptions || Utils.isMobileDevice() || imageIsIllegal) return []
        const {
            aiEdit, // AI编辑
            changeModel, // 更改模特
            changeBg, // 更改场景
            changeAction, // 更改动作
            replica, // 复刻
            bookmark, // 收藏
            download, // 下载
            share, // 分享
            deleteTask // 删除
        } = props.menuOptions
        const res = []
        if (aiEdit) res.push({
            key: 'aiEditor',
            label: 'AI编辑',
            icon: 'aiEditor',
            // vipRequired: true,
            onClick: () => toAiEditor()
        })
        if (changeModel) res.push({
            key: 'changeModel',
            label: props.imageType == IMAGE_TYPE.CLOTHES ? '更换容貌' : '更换模特',
            icon: 'changeModel',
            onClick: () => onChangeModel()
        })
        if (changeBg) res.push({
            key: 'changeBg',
            label: '更换场景',
            icon: 'changeBg',
            onClick: () => onChangeBg()
        })
        if (changeAction) res.push({
            key: 'changeAction',
            label: '更换动作',
            icon: 'changeAction',
            onClick: () => onChangeAction()
        })
        if (replica) res.push({
            key: 'replica',
            label: '复用模特/场景',
            icon: 'replica',
            onClick: () => onReplica()
        })
        if (bookmark) res.push({
            key: 'bookmark',
            label: props.bookmarked ? '取消收藏' : '收藏',
            icon: props.bookmarked ? 'bookmarked' : 'bookmark',
            onClick: () => toBookmark()
        })
        if (download) res.push({
            key: 'download',
            content: (
                <Popover
                    key={'download'}
                    placement="leftTop"
                    content={downloadMenuContent}
                    arrow={false}
                    overlayInnerStyle={{ borderRadius: '12px', padding: 0, background: 'transparent', boxShadow: 'none' }}
                    trigger={"hover"}
                    getPopupContainer={triggerNode => triggerNode}
                >
                    <div
                        key={'download'}
                        className={styles.MenuItem}
                    >
                        <i className={`${styles.Icon} ${styles['download']}`}></i>
                        <span>下载</span>
                    </div>              
                </Popover>
            ),
            icon: 'download',
        })
        if (share) res.push({
            key: 'share',
            label: '分享',
            icon: 'share',
            onClick: () => toShare()
        })
        if (deleteTask) res.push({
            key: 'delete',
            label: '删除',
            icon: 'delete',
            onClick: () => {
                PSAIModal.confirm({
                    centered: true,
                    title: props.imageType == 'tryonVideo' ? "删除视频" : "删除图片",
                    content: `请确认是否删除此${props.imageType == 'tryonVideo' ? "视频" : "结果图"}`,
                    icon: null,
                    onOk: hadnleDeleteTask
                })
            }
        })
        return res
    }, [props.menuOptions, props.bookmarked])

    const menuContent = (
        <div className={styles.MenuContianer} onClick={e => e.stopPropagation()}>
        {
            dropdownMenus.map(menu => {
                if (!menu) return null
                if (menu.content) return menu.content
                return (
                    <div
                        key={menu.key}
                        className={styles.MenuItem}
                        onClick={(e) => {
                            if (menu.disabled) return
                            menu.onClick(e);
                            setMenuOpen(false);
                        }}
                    >
                        <i className={`${styles.Icon} ${styles[menu.icon]}`}></i>
                        <span>{menu.label}</span>
                        { menu.vipRequired && <i className={styles.VipRequired}></i> }
                    </div>
                )
            })
        }
        </div>
    )

    // 选择
    const checkPicStatus = (checked) => {
        // 选中
        if (checked) {
            props.setSelectedPics((preVal) => ([
                ...preVal,
                props.imageId,
            ]));
            return;
        }
        props.setSelectedPics((preVal) => {
            const index = preVal.indexOf(props.imageId);
            if (index === -1) {
                return preVal;
            }
            preVal.splice(index, 1)
            return [...preVal];
        });
    }

    const onClickImg = () => {
        if (props.isSelected) return
        props.clickFn && props.clickFn()
    }

    const [menuOpen, setMenuOpen] = useState(false)

    const [shareOpen, setShareOpen] = useState()
    const [shareImg, setShareImg] = useState()
    const toShare = () => {
        setShareImg(mainImage)
        setShareOpen(true)
    }

    const videoRef = useRef()
    const [isPlaying, setIsplaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const handelVideoPlay = async (play) => {
        try {
            if (play) await videoRef.current.play()
            else await videoRef.current.pause()
        } catch (err) {
            console.error(err)
        }
    }
    const handlPlay = () => setIsplaying(true)
    const handlePause = () => setIsplaying(false)
    const handleEnded = () => setIsplaying(false)
    const handleUpdateTime = (e) => {
        setCurrentTime(videoRef.current.currentTime)
        setDuration(videoRef.current.duration)
    }

    const mouseEnterable = useRef(true)
    const handleMouseEnter = () => {
        if (!mouseEnterable.current) return
        mouseEnterable.current = false
        if (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO) {
            handelVideoPlay(true)
        }
    }

    const handleMouseLeave = () => {
        mouseEnterable.current = true
        if (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO) {
            handelVideoPlay(false)
        }
    }

    const normalOverlayContent = (
        <>
        {
            !imageIsIllegal &&
            props.taskToolOptions &&
            <div className={styles.m_box_item_float}>
                <TaskTools
                    imageType={props.imageType}
                    topic={props.topic}
                    mainImage={mainImage}
                    taskId={props.taskId}
                    imageId={props.imageId}
                    bookmarked={props.bookmarked}
                    showHDPreview={props.taskToolOptions.hdPreview} // 高清预览按钮
                    videoPlayer={ // 视频播放组件
                        props.imageType != IMAGE_TYPE.COMMODITY_VIDEO && props.imageType != IMAGE_TYPE.MODEL_VIDEO ? false :
                        {
                            isPlaying,
                            currentTime,
                            duration,
                            showVideoBtn: false,
                            showProgressBar: false,
                            onPlay: handelVideoPlay
                        }
                    }
                    showBookmark={props.taskToolOptions.bookmark} // 收藏按钮
                    showAIEditor={props.taskToolOptions.aiEdit} // ai编辑按钮
                    showDownload={props.taskToolOptions.download} // 下载按钮
                    downloadOpenCb={setDownloadOpen}
                    hdPayed={hdPayed}
                    withoutMask
                />
            </div>
        }
        <Popover
            placement="rightTop"
            content={menuContent}
            open={menuOpen}
            arrow={false}
            overlayInnerStyle={{ borderRadius: '12px', padding: 0, background: 'transparent' }}
            onOpenChange={value => setMenuOpen(value)}
        >
            <div className={styles.m_box_item_menu_content} onClick={e => e.stopPropagation()}>
                <div className={styles.m_box_item_menu_img} onClick={() => setMenuOpen(true)}>
                    <i></i><i></i><i></i>
                </div>
            </div>
        </Popover>
        </>
    )

    const selectedOverlayContent = (
        <div style={{position: 'absolute', width: '100%', height: '100%'}} onClick={() => checkPicStatus(!props.checked)}>
            <Checkbox
                className={styles.m_box_item_select}
                checked={props.checked}
            ></Checkbox >
        </div>
    )

    return (
        <>
        <div
            className={styles.m_box_item_comp}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={reviewStatus?null: onClickImg}
        >
            <div className={`${styles.m_box_item} ${styles.m_box_item_comp_item} ${downloadOpen && styles.download_open} ${menuOpen && styles.dropdown_open}`}>
            {
                props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO ? 
                <video
                    ref={videoRef}
                    onPlay={handlPlay}
                    onPause={handlePause}
                    onEnded={handleEnded}
                    onTimeUpdate={handleUpdateTime}
                    src={mainImage}
                    muted
                    loop
                    disablePictureInPicture
                    width={"100%"}
                    height={"100%"}></video> :
                <Image
                    width={"100%"}
                    height={"100%"}
                    style={{objectFit: 'contain'}}
                    preview={false}
                    src={Utils.getImgUrlWithWebp(mainImage, true)}
                />
            }
            {
                isHDPreview &&
                <span className={styles.HDRibbon}>高清</span>
            }
            {
                reviewStatus? null: !props.isSelected ? 
                normalOverlayContent :
                selectedOverlayContent
            }
            </div>
            {
                props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO ? 
                <span className={styles.video_flag}></span> : ""
            }
        </div>
        <ShareModal open={shareOpen} shareImg={shareImg} closeFunc={() => setShareOpen(false)} />
        </>
    )
}

export default PicView;
