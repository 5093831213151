import { Button } from 'antd'
import styles from './shapeShiftCtrl.module.scss'
import { colorPrimary } from '@utils/AppContext';

const ShapeShiftCtrl = (props) => {
    return (
        <div className={styles.ctrlContainer}>
            <div className={styles.shiftTips}>随心变形</div>
            <div className={styles.shiftGif}>
                <video src={require('@assets/video/shape.mp4')} autoPlay={!props.disabled} muted loop disablePictureInPicture></video>
            </div>
            <ul className={styles.shiftContent}>
                <li>点击鼠标锁定区域</li>
                <li>
                    <p>在区域内标记变形的 <span style={{color: colorPrimary}}>起点</span> 和 <span style={{color: 'rgba(255, 36, 36, 1)'}}>终点</span></p>
                    <p className={styles.shiftContent_tips}>Tips：可选中标记点进行拖动调整和删除</p>
                </li>
                <li>点击“应用变形”</li>
            </ul>
            <Button disabled={!props.areaLocked || props.isLoading} className={styles.shiftReselect} block type="primary" onClick={props.unlockAreaFunc}>重选变形区域</Button>
            {/* <Button loading={props.isLoading} disabled={!props.shapeable} className={styles.shiftShape} block type="primary" onClick={props.shapeFunc}>应用变形</Button> */}
        </div>
    )
}

export default ShapeShiftCtrl