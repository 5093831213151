import styles from './RightComp.module.scss'
import useProjectDetail from './hooks/useProjectDetail';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AppContext } from '@utils/AppContext';
import TaskItem from '@view/compoents/taskItem/taskItem'
import utils from '@utils/utils'
import { GenerateContext } from '@utils/GenerateContext';
import { IMAGE_TYPE } from '@utils/CONST';
import PreviewImage from '@view/compoents/previewImage/previewImage';
import API from '@api/api'
import { useNavigate } from "react-router-dom";
import { Button, Empty, Spin } from 'antd';

const REDIRECT_URL = '/home/homemain/photoProjectDetail';

const RightComp = () => {
    const navigate = useNavigate();
    const { update, forceUpdate, updateConfig } = useContext(AppContext) // 触发更新flag
    const {
        imageType,
        projectName,
        rightProjectId,
    } = useContext(GenerateContext)

    useEffect(() => {
        // 触发一次拉取数据
        forceUpdate({})
    }, [])

    const { projectDetail, loading } = useProjectDetail({
        projectId: rightProjectId,
        update
    })

    const showTasks = useMemo(() => {
        const projectName = projectDetail.projectName
        const allTasks = projectDetail?.taskList?.filter(task => {
            return !(
                task.status == 'failed' ||
                (task.status == 'succeeded' && task.results.length == 0)
            )
        }).reverse()
        if (!allTasks) return []
        return allTasks.map(tempTask => {
            const loadingMaskList = tempTask.loadingMask.split(',')
            let loadingMask = ''
            if (loadingMaskList.length > 0) loadingMask = loadingMaskList[0]
            const taskResults = (
                tempTask.results &&
                tempTask.results.length > 0 ? tempTask.results : [{}]
            ).map((res, index) => Object.assign(res, {
                key: res.id ? res.id : tempTask.taskId + '_' + index,
                loadingMask: loadingMask,

                projectName: projectName,
                imageType: imageType,
                stage: tempTask.stage,
                status: tempTask.status,
                progress: tempTask.progress,
                waitingTime: tempTask.waitingTime,
                taskId: tempTask.taskId,
                bookmark: tempTask.bookmark,
                personTitle: tempTask.personTitle,
                personRealTitle: tempTask.personRealTitle,
                backgroundTitle: tempTask.backgroundTitle,
                backgroundRealTitle: tempTask.backgroundRealTitle,
                videoTryonTitle: tempTask.videoTryonTitle,
                hairTagText: tempTask.hairTagText,
                faceTagText: tempTask.faceTagText,
                updatedTime: tempTask.updatedTime,
                definedFlag: tempTask.definedFlag,
                prompt: tempTask.prompt,
                refImg: tempTask.refImg,
                inputImage: tempTask.inputImage,
                reviewStatus: tempTask.reviewStatus, // 审核状态 2-拒绝
                resolution: tempTask.resolution, // 商品视频分辨率
                keepPose: tempTask.keepPose, // 保持模特
                keepBkg: tempTask.keepBkg, // 保持场景
                topic: tempTask.topic,
            }))
            return [...taskResults]
        }).flat()
    }, [projectDetail])

    // 自动滚动到生成中的task处
    const jumpOnceRef = useRef(false)
    useEffect(() => {
        if (rightProjectId) jumpOnceRef.current = false
    }, [rightProjectId])
    useEffect(() => {
        if (update?.jumpOnce) jumpOnceRef.current = false
    }, [update])
    useEffect(() => {
        if (!jumpOnceRef.current) {
            jumpOnceRef.current = true
            let jumpto = -1
            for (let i = 0; i < showTasks.length; i++) {
                const task = showTasks[i]
                if (task.status != 'succeeded' && task.status != 'failed') {
                    jumpto = i
                    break
                }
            }
            if (jumpto >= 0) {
                // console.log('scrool', `task_no${jumpto+1}`)
                setTimeout(() => {
                    const targetElement = document.getElementById(`task_no${jumpto+1}`)
                    targetElement && targetElement.scrollIntoView({ behavior: 'smooth' })
                }, 100)
            }
        }
    }, [showTasks])

    const loadingMaskMapRef = useRef({})
    const [loadingMaskMap, setLoadingMaskMap] = useState({})

    useEffect(() => {
        const loadMaskUrls = projectDetail
            ?.taskList
            ?.filter(item => !item.id && item.loadingMask && !(
                item.status == 'failed' ||
                item.status == 'succeeded'
            ))
            .map(item => item.loadingMask.split(',')).flat() || []
        if (loadMaskUrls.length == 0) return
        setTimeout(() => {
            for (let url of loadMaskUrls) {
                if (!loadingMaskMapRef.current[url]) {
                    loadingMaskMapRef.current[url] = 'processing'
                    utils.urlToBase64(url+ '?x-oss-process=style/thumbnail-width-500').then(base64 => {
                        utils.convertImgColor(base64, [[0,0,0,255], [0,0,0,0]], [['*','*','*'], [230, 230, 230]]).then(res => {
                            loadingMaskMapRef.current[url] = res
                            setLoadingMaskMap(Object.entries(loadingMaskMapRef.current).reduce((acc, [key, value]) => {
                                if (value !== 'processing') acc[key] = value
                                return acc
                            }, {}))
                        })
                    })
                }
            }
        }, 100)
    }, [projectDetail])

    const changeModelFn = () => {}
    const changeBgFn = () => {}
    const selectedPics = []
    const setSelectedPics = () => {}

    const [previewVisible, setPreviewVisible] = useState(false) // 预览图片\相册
    const [previewCurrent, setPreviewCurrent] = useState()
    const previewItems = useMemo(() => {
        return showTasks ? showTasks.filter(item => !!item.image) : []
    }, [showTasks])
    const clickFn = (result) => {
        // console.log('open preview', result)
        const items = showTasks.filter(item => !!item.image)
        setPreviewVisible(true)
        setPreviewCurrent(items.findIndex(item => item.key == result.key))
    }

    const isLoop = useRef(false)
    const isMounted = useRef(true)
    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    const loopFunc = () => {
        forceUpdate({})
        if (isLoop.current && isMounted.current) {
            setTimeout(() => {
                loopFunc()
            }, 2000)
        }
    }
    const setReadFlag = () => {
        API.setProjectReadFlag({projectId: rightProjectId}).then(() => {
            forceUpdate({})
        })
    }
    useEffect(() => {
        const task = projectDetail?.taskList?.find(item => (
            (item.status != 'succeeded' && item.status != 'failed') ||
            item.results.find(item => item.hdStatus == 'started')
        ))
        if (task) {
            if (!isLoop.current) {
                isLoop.current = true
                loopFunc()
            }
        } else {
            if (isLoop.current) {
                isLoop.current = false
                setReadFlag()
            }
        }
    }, [showTasks])

    return (
        <div className={styles.Container}>
            <p className={styles.Title}>
                <span>生成结果</span>
            </p>
            <div className={`${styles.TaskListContainer} _beauty_scrollbar`}>
                <div className={`${styles.TaskList}`}>
                {showTasks.map((result, index) => (
                    <div id={`task_no${index+1}`} key={result.key}>
                        <TaskItem
                            key={result.key}
                            topic={result.topic}
                            projectId={rightProjectId}
                            taskId={result.taskId}
                            imageId={result.id}
                            imageType={imageType}
                            projectName=""
                            downloadCb={() => donwloadOneTaskPic(result)}
                            clickFn={() => clickFn(result)}
                            setMainImgCb={() => setMainPic(result)}
                            changeModelCb={changeModelFn}
                            changeBgCb={changeBgFn}
                            isSelected={false}
                            checked={selectedPics.includes(result?.id)}
                            bookmarked={result.bookmark}
                            setSelectedPics={setSelectedPics}
                            menuOptions={{
                                aiEdit: imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO,
                                bookmark: true,
                                download: true,
                                share: true,
                                deleteTask: true,
                            }}
                            taskToolOptions={{
                                bookmark: false,
                                aiEdit: false,
                                download: false,
                                videoPlayer: imageType == IMAGE_TYPE.COMMODITY_VIDEO ||  imageType == IMAGE_TYPE.MODEL_VIDEO,
                                hdPreview: false
                            }}
                            isProject={false}
                            loadingMask={loadingMaskMap[result.loadingMask]}
                            taskResult={result}
                        />
                    </div>
                ))}
                </div>
                {
                    loading &&
                    <Spin className={styles.TaskLoading} />
                }
                {
                    !loading &&
                    showTasks.length == 0 &&
                    <Empty className={styles.TaskEmpty} description={false}/>
                }
            </div>
            <PreviewImage
                open={previewVisible}
                closeFunc={() => setPreviewVisible(false)}
                items={previewItems}
                current={previewCurrent}
                showBookmark={true}
                imageType={imageType}
                projectName={projectName}
            />
            <Button
                className={styles.AllTasks}
                onClick={() => navigate(REDIRECT_URL, {state: {id: rightProjectId}})}
            >
                查看全部
            </Button>
        </div>
    )
}

export default RightComp