import { Button, Image, Input, Modal, Popover, Upload, message } from 'antd'
import styles from './index.module.scss'
import { useEffect, useMemo, useState } from 'react'
import { IMAGE_TYPE } from '@utils/CONST'
import { useLocation } from 'react-router-dom'
import { PlusOutlined, QuestionOutlined } from '@ant-design/icons';
import eventBus from '@utils/eventBus';
import Utils from '@utils/utils'
import API from '@api/api'
import PSAIModal from '@PSAIComponents/PSAIModal'

const Feedback = (props) => {
    const [comments, setComments] = useState('');
    const [fileList, setFileList] = useState([]);
    const [contact, setContact] = useState('');
    const [loading, setLoading] = useState()

    const uploadButton = (
        <div>
          <PlusOutlined style={{fontSize: '22px', color: '#979797'}} />
        </div>
    );

    function feedback (fileUrls) {
        return API.feedback({
            comments: comments,
            contact: contact,
            attachments: fileUrls,
        }).then(res => {
            if (res.code == 0) {
                message.success('感谢您提交的问题，我们将对问题进行改进并反馈！', 10)
            } else {
                message.error(res.message)
            }
        })
    }

    const onSubmit = async () => {
        try {
            if(!localStorage.getItem('token')) {
                message.warning('请先登录账号！')
                eventBus.emit('login')
                return props.onCancel()
            }
            setLoading(true)
            if (!comments) {
                message.warning('请输入问题描述')
                return setLoading(false)
            }
            let fileUrls = []
            if (fileList.length > 0) {
                // AAA
                const list = fileList.map(item => Utils.checkImageIslegal(item.originFileObj, 0))
                fileUrls = await Promise.all(list) // 这不需要存审核ID
                if (fileUrls.findIndex(item => !item) >= 0) return setLoading(false)
                const isLegal = fileUrls.some(item => !item.isLegal);
                if(isLegal) {
                    PSAIModal.confirm({
                        content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                        okText: '检查图片',
                        cancelText: '取消',
                        onOk() {},
                        onCancel() {},
                    })
                    return false
                }
            }
            await feedback(fileUrls.map(item => item.data))
            
            return props.onCancel()
        } catch (err) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    return (
        <div className={styles.FeedbackContainer}>
            <div className={styles.FormContent}>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_comments">问题描述<span>*</span></label>
                    <div className={styles.FormItemContent}>
                        <Input.TextArea
                            id="feedback_comments"
                            showCount
                            maxLength={300}
                            style={{height: 150}}
                            onChange={e => setComments(e.target.value)}
                            value={comments}
                            placeholder="请详细描述您遇到的问题，我们会努力改进为您提供更好的服务~"
                        />
                    </div>
                </div>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_files">问题图片</label>
                    <div className={styles.FormItemContent}>
                        <Upload
                            id="feedback_files"
                            accept='image/jpeg,image/png,image/webp,image/avif'
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={() => false}
                            onPreview={() => {return false}}
                            onChange={(fileList) => setFileList(fileList.fileList)}
                            maxCount={3}
                            showUploadList={{showPreviewIcon: false}}
                        >
                            {fileList.length >= 3 ? null : uploadButton}
                        </Upload>
                    </div>
                </div>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_contact">您的联系方式</label>
                    <div className={styles.FormItemContent}>
                        <Input
                            id="feedback_contact"
                            value={contact}
                            maxLength={20}
                            onChange={e => setContact(e.target.value)}
                            placeholder="手机号/邮箱/微信号"
                        />
                    </div>
                </div>
            </div>
            <div className={styles.BtnContent}>
                <Button onClick={onCancel}>取消</Button>
                <Button type="primary" onClick={onSubmit} loading={loading}>提交反馈</Button>
            </div>
        </div>
    )
}

const GuideContent = (props) => {
    return (
        <div className={styles.GuideContent}>
            <video
                src={
                    props.guideType == IMAGE_TYPE.COMMODITY ?
                        require('@assets/video/guide_commodity.mp4') :
                        props.guideType == IMAGE_TYPE.DRESSFORM ?
                            require('@assets/video/guide.dressform.mp4') :
                            props.guideType == IMAGE_TYPE.CLOTHES?
                                require('@assets/video/guide_clothing.mp4'):
                                require('@assets/video/guide.mp4')
                }
                autoPlay
                muted
                loop
                disablePictureInPicture
                width={280}
                height={360}
            ></video>
        </div>
    )
}

const HelperContent = (props) => {
    const [activeKey, setActiveKey] = useState()
    const [qrcodeVisible, setQrcodeVisible] = useState(false)
    const [qrcode, setQrcode] = useState()

    const onClick = (item) => {
        props.onClick(item)
        setActiveKey(item.key)
    }

    return (
        <div className={styles.HelperContent}>
            <div className={styles.HelprQRCode}>
                <div>
                    <img onClick={() => {setQrcode(Utils.qrcodeSales);setQrcodeVisible(true)}} src={Utils.qrcodeSales} alt="扫码联系我们" />
                    <p>售前咨询</p>
                </div>
                <div>
                    <img onClick={() => {setQrcode(Utils.qrcodeCommunity);setQrcodeVisible(true)}} src={Utils.qrcodeCommunity} alt="扫码联系我们" />
                    <p>加入社群</p>
                </div>
                <Image
                    style={{ display: 'none' }}
                    preview={{
                        getContainer: '#root',
                        visible: qrcodeVisible,
                        imageRender: () => (<img style={{width: 400}} src={qrcode} alt="扫码联系我们" />),
                        toolbarRender: () => null,
                        onVisibleChange: (value) => setQrcodeVisible(value),
                    }}
                />
            </div>
            <div className={styles.HelperList}>
            {
                props.items.map(item => (
                    <div
                        key={item.key}
                        className={`${styles.HelperItem} ${activeKey == item.key && styles.selected}`}
                        onClick={() => onClick(item)}
                    >
                        <span>{item.label}</span>
                    </div>
                ))
            }
            </div>
        </div>
    )
}

const FloatHelper = () => {
    const location = useLocation()
    const [feedbackOpen, setFeedbackOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const [showGuide, setShowGuide] = useState(false)
    
    useEffect(() => {
        if (!open) setShowGuide(false)
    }, [open])

    const guideType = useMemo(() => {
        const pathname = location.pathname
        if (pathname == '/home/homemain/model') return IMAGE_TYPE.MODEL
        else if (pathname == '/home/homemain/dressform') return IMAGE_TYPE.DRESSFORM
        else if (pathname == '/home/homemain/clothes') return IMAGE_TYPE.CLOTHES
        else if (pathname == '/home/homemain/commodity') return IMAGE_TYPE.COMMODITY
    }, [location.pathname])

    const items = useMemo(() => {
        if (guideType) {
            return [{
                label: "意见反馈",
                key: 1
            }, {
                label: "新手指南", // video
                key: 2
            }, {
                label: "使用教程", // https://arcsoft.feishu.cn/docx/WrEFdAObEo0KWkxlwM3cigkhnxg
                key: 3,
                link: "https://arcsoft.feishu.cn/docx/WrEFdAObEo0KWkxlwM3cigkhnxg"
            }]
        } else {
            return [{
                label: "意见反馈",
                key: 1
            }, {
                label: "使用教程", // https://arcsoft.feishu.cn/docx/WrEFdAObEo0KWkxlwM3cigkhnxg
                key: 3,
                link: "https://arcsoft.feishu.cn/docx/WrEFdAObEo0KWkxlwM3cigkhnxg"
            }]
        }
    }, [guideType])

    const onClick = (item) => {
        if (item.link) window.open(item.link)
        else {
            if (item.key == 1) {
                setOpen(false)
                setFeedbackOpen(true)
            } else {
                setShowGuide(true)
            }
        }
    }

    return (
        <div>
            <Popover
                open={open}
                overlayClassName={styles.HelperPopover}
                placement="topRight"
                content={
                    !showGuide ?
                    <HelperContent items={items} onClick={onClick} /> :
                    <GuideContent guideType={guideType} />
                }
                arrow={false}
                trigger={"hover"}
                onOpenChange={(value) => setOpen(value)}
                getPopupContainer={() => document.getElementById('root')}
            >
                <div className={styles.HelperTrigger}>
                    <QuestionOutlined />
                </div>
            </Popover>
            <PSAIModal
                title="意见反馈"
                open={feedbackOpen}
                footer={null}
                maxWidth={664}
                onCancel={() => setFeedbackOpen(false)}
            >
                <Feedback onCancel={() => setFeedbackOpen(false)} />
            </PSAIModal>
        </div>
    )
}

export default FloatHelper