import { forwardRef, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import styles from './MattingOpt.module.scss'
import { AppContext } from '@utils/AppContext';
import Utils from '@utils/utils'
import MattingOptCanvas from "./MattingOptCanvas";
import ActionGroup from "./ActionGroup";
import IconPaint from '@assets/images/icon_paint.svg';
import IconPaintActived from '@assets/images/icon_paint_actived.svg';
import IconErase from '@assets/images/icon_erase.svg';
import IconEraseActived from '@assets/images/icon_erase_actived.svg';
import IconAdd from '@assets/images/icon_add.svg'
import IconRemove from '@assets/images/icon_remove.svg';
import IconUndo from '@assets/images/icon_undo.svg'
import IconUndoActived from '@assets/images/icon_undo_actived.svg';
import IconReset from '@assets/images/icon_reset.svg'
import IconResetActived from '@assets/images/icon_reset_actived.svg';
import { Button, Input, Slider, Space, message } from "antd";
import { DragOutlined, MinusOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import Rcslider from "@view/compoents/RcSlider/RcSlider.jsx"
import API from '@api/api'
import MattingOptRightCanvas from "./MattingOptRightCanvas";
import useResizeObserver from "@utils/useResizeObserver";
import DragAndScale from './DragAndScale'

const MattingOpt = forwardRef((props, ref) => {

    const isMounted = useRef(true) // 判断组件是否已销毁
    const { setGlobalLoading } = useContext(AppContext);
    const blendedImageRef = useRef() // mask与原图混合后的图像
    const [whiteMaskUrl, setWhiteMaskUrl] = useState(props.whiteMask) // 白底mask segmentResult[4]
    // const whiteMaskBase64Ref = useRef() // 黑白mask的base64格式
    // const [blueMaskUrl, setBlueMaskUrl] = useState(props.blueMask) // 蓝色mask segmentResult[1]（来自算法生成）
    const [blueMaskBase64, setBlueMaskBase64] = useState() // 蓝色mask的base64格式（来自算法生成）
    const [blendedBlueMaskBase64, setBlendedBlueMaskBase64] = useState() // 混合后的蓝色mask的base64格式
    const blueMaskBase64OriginRef = useRef()
    const [inputImageUrl, setInputImageUrl] = useState(props.inputImage) // 输入图 segmentResult[0]
    const [inputImageBase64, setInputImageBase64] = useState() // 输入图的base64格式
    const inputImageBase64OriginRef = useRef()

    const [imgWidth, setImgWidth] = useState(0)
    const [imgHeight, setImgHeight] = useState(0) 
    const imgWidthRef = useRef(0) // 图片原始宽度
    const imgHeightRef = useRef(0) // 图片原始高度

    const [displayScale, setDisplayScale] = useState()
    const [displayWidth, setDisplayWidth] = useState() // 图片默认显示宽度
    const [displayHeight, setDisplayHeight] = useState() // 图片默认显示高度
    const canvasRef = useRef()
    const [inited, setInited] = useState(false)

    const updatedRef = useRef(false)

    const setUpdated = (val) => updatedRef.current = val
    // const setWhiteMaskBase64 = (val) => whiteMaskBase64Ref.current = val

    useImperativeHandle(ref, () => ({
        saveHandle
    }))

    // 检测第一次交互式分割是否完成
    useEffect(() => {
        if (!props.aiInteractiveNpy) setGlobalLoading('抠图优化中')
        else setGlobalLoading(false)
    }, [props.aiInteractiveNpy])

    useEffect(() => {
        // 获得混合图片
        Promise.all([
            Utils.urlToBase64(props.blueMask),
            Utils.urlToBase64(props.inputImage)
        ]).then(([blueMaskBase64, inputImageBase64]) => {
            blueMaskBase64OriginRef.current = blueMaskBase64
            setBlueMaskBase64(blueMaskBase64)
            inputImageBase64OriginRef.current = inputImageBase64
            setInputImageBase64(inputImageBase64)
            setWhiteMaskUrl(props.whiteMask)
            // Utils.urlToBase64(props.whiteMask).then(setWhiteMaskBase64)
            // 获得canvas尺寸
            Utils.getImageDimensions(inputImageBase64).then(({width, height}) => {
                imgWidthRef.current = width
                imgHeightRef.current = height
                setImgWidth(width)
                setImgHeight(height)
            })
        })
        return () => {
            setGlobalLoading(false)
            isMounted.current = false
        }
    }, [])

    const [wrapWidth, setWrapWidth] = useState(0)
    const [wrapHeight, setWrapHeight] = useState(0)
    const canvasWrapRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
            setWrapHeight(entry.contentRect.height)
        }
    })    
    useEffect(() => {
        if (!wrapWidth || !wrapHeight) return
        if (!imgWidth || !imgHeight) return
        // 获得canvas尺寸
        const size = Utils.getImgMaxSizeInContainer(
            imgWidth,
            imgHeight,
            wrapWidth,
            wrapHeight
        )
        setDisplayScale(size.width / imgWidth)
        setDisplayWidth(size.width)
        setDisplayHeight(size.height)
        setInited(true)
    }, [imgWidth, imgHeight, wrapWidth, wrapHeight])

    const saveHandle = async () => {
        if (!updatedRef.current) return
        
        // --- 获得前景图片 begin ---
        // 边缘优化，将半透明改为不透明
        const blueMaskBase64AfterOpaque = await Utils.setOpaquePixels(blendedBlueMaskBase64)
        // 与mask融合后的前景图片
        const blended = await Utils.getForegroundImage(inputImageBase64, blueMaskBase64AfterOpaque)
        blendedImageRef.current = blended
        // console.log('===blended===', blended)
        // -- 获得前景图片 end ---

        // 混合后的黑白mask
        const blendWhiteMaskBase = await canvasRef.current.getBlendWhiteMask(blendedBlueMaskBase64)

        const [res1, res2, res3] = await Promise.all([
            Utils.resizeImage(blendedBlueMaskBase64, imgWidthRef.current, imgHeightRef.current), // 混合后的半透明蓝色mask editedOutput[1]
            Utils.resizeImage(blendWhiteMaskBase, imgWidthRef.current, imgHeightRef.current), // 混合后的黑白mask editedOutput[4]
            Utils.resizeImage(blendedImageRef.current, imgWidthRef.current, imgHeightRef.current), // 前景图片
        ])

        const [res4, res5] = await Promise.all([
            Utils.uploadBase64Image(res1),
            Utils.uploadBase64Image(res2),
        ])
        if (res4 && res5) {
            return {
                blueMask: res4, // editedOutput[1]
                whiteMask: res5, // editedOutput[4]
                blendedImage: res3 // 前景图片
            }
        } else return false
    }

    // 交互时分割模式 0-增加 1-减少
    const [interactiveMode, setInteractiveMode] = useState('add')
    const interactiveModeRef = useRef('add')
    useEffect(() => {
        interactiveModeRef.current = interactiveMode
        if (!interactiveMode) return
        setPaintMode()
        setMoveable(false)
    }, [interactiveMode])
    const interactiveItems = [{
        label: '增加选区',
        icon: IconAdd,
        activedIcon: IconAdd,
        value: 'add',
        hover: false
    }, {
        label: '减少选区',
        icon: IconRemove,
        activedIcon: IconRemove,
        value: 'remove',
        hover: false
    }]

    // 涂抹选项
    const [paintMode, setPaintMode] = useState()
    const paintModeRef = useRef()
    const [paintSize, setPaintSize] = useState(20)
    const paintSizeRef = useRef(20)
    const [eraseSize, setEraseSize] = useState(20)
    const eraseSizeRef = useRef(20)
    useEffect(() => {
        paintModeRef.current = paintMode
        if (!paintMode) return
        setInteractiveMode()
        setMoveable(false)
    }, [paintMode])
    useEffect(() => {
        paintSizeRef.current = paintSize
        console.log('paintSize change', paintSize)
    }, [paintSize])
    useEffect(() => {
        eraseSizeRef.current = eraseSize
        console.log('eraseSize change', eraseSize)
    }, [eraseSize])
    const paintItems = [{
        label: '手动涂抹',
        icon: IconPaint,
        activedIcon: IconPaintActived,
        value: 'paint',
        hover: false,
        popover: (
            <Rcslider
                title={"画笔大小"}
                defaultValue={20}
                value={paintSize}
                onChange={setPaintSize}
                min={1}
                max={50}
                disabled={paintMode != 'paint'}
                type={'paint'}
            />
        )
    }, {
        label: '手动擦除',
        icon: IconErase,
        activedIcon: IconEraseActived,
        value: 'erase',
        hover: false,
        popover: (
            <Rcslider
                title={"橡皮大小"}
                defaultValue={20}
                value={eraseSize}
                onChange={setEraseSize}
                min={1}
                max={50}
                disabled={paintMode != 'erase'}
                type={'erase'}
            />
        )
    }]

    // 其他操作
    const undoListRef = useRef([])
    const [undoList, setUndoList] = useState([]) // 待撤销列表
    const redoListRef = useRef([])
    const [redoList, setRedoList] = useState([]) // 重做
    const clearRedo = () => {
        redoListRef.current = []
        setRedoList(redoListRef.current)
    }
    const addUndo = (params) => {
        params = Object.assign(params, {
            interactiveMode: interactiveModeRef.current,
            paintMode: paintModeRef.current,
            paintSize: paintSizeRef.current,
            eraseSize: eraseSizeRef.current,
            moveable: moveableRef.current,
            zoom: zoomRef.current,
        })
        undoListRef.current = [...undoListRef.current, {params}]
        setUndoList(undoListRef.current)
    }
    const addRedo = (params) => {
        params = Object.assign(params, {
            interactiveMode: interactiveModeRef.current,
            paintMode: paintModeRef.current,
            paintSize: paintSizeRef.current,
            eraseSize: eraseSizeRef.current,
            moveable: moveableRef.current,
            zoom: zoomRef.current,
        })
        redoListRef.current = [...redoListRef.current, {params}]
        setRedoList(redoListRef.current)
    }
    const reset = () => {
        setBlueMaskBase64(blueMaskBase64OriginRef.current)
        setInputImageUrl(props.inputImage)
        setInputImageBase64(inputImageBase64OriginRef.current)
        setWhiteMaskUrl(props.whiteMask)
        // Utils.urlToBase64(props.whiteMask).then(setWhiteMaskBase64)
        setUpdated(false)
        // 重置按钮
        setInteractiveMode('add')
        setPaintMode()
        setPaintSize(20)
        setEraseSize(20)
        setMoveable()
        setZoom(1)
        // 重置canvas位置，清空canvas内容
        canvasRef.current.resetCanvas()
        canvasRef.current.clearCanvas()
        // 清空撤销和重置
        undoListRef.current = []
        setUndoList(undoListRef.current)
        redoListRef.current = []
        setRedoList(redoListRef.current)
    }
    const handleUndo = () => {
        if (undoListRef.current.length == 0) return
        const undo = undoListRef.current.pop()
        setUndoList(undoListRef.current)
        const {
            interactiveMode,
            paintMode,
            paintSize,
            eraseSize,
            moveable,
            zoom,
        } = undo.params
        setInteractiveMode(interactiveMode)
        setPaintMode(paintMode)
        setPaintSize(paintSize)
        setEraseSize(eraseSize)
        setMoveable(moveable)
        setZoom(zoom)
        canvasRef.current.undoCanvas(undo)
    }
    const handleRedo = () => {
        if (redoListRef.current.length == 0) return
        const redo = redoListRef.current.pop()
        setRedoList(redoListRef.current)
        const {
            interactiveMode,
            paintMode,
            paintSize,
            eraseSize,
            moveable,
            zoom,
        } = redo.params
        setInteractiveMode(interactiveMode)
        setPaintMode(paintMode)
        setPaintSize(paintSize)
        setEraseSize(eraseSize)
        setMoveable(moveable)
        setZoom(zoom)
        canvasRef.current.redoCanvas(redo)
    }
    const actionItems = [{
        label: '撤销',
        icon: IconUndo,
        activedIcon: IconUndoActived,
        value: 0,
        hover: false,
        disabled: undoList.length == 0,
        onClick: () => handleUndo()
    }, {
        label: '恢复',
        icon: IconUndo,
        activedIcon: IconUndoActived,
        iconStyle: {transform: 'rotateY(-180deg)'},
        value: 1,
        hover: false,
        disabled: redoList.length == 0,
        onClick: () => handleRedo()
    }, {
        label: '重置',
        icon: IconReset ,
        activedIcon: IconResetActived,
        iconStyle: {transform: 'rotate3d(1, 1, 1, 15deg)'},
        value: 2,
        hover: false,
        onClick: () => reset()
    }]

    // 撤销操作                    
    function handleKeydown(event) {
        if (event.ctrlKey) {
            if (event.key == 'z') { // CTRL + Z
                handleUndo()              
            }
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        }
    }, [])

    // 移动控制
    const [viewportOffset, setViewportOffset] = useState({x: 0, y: 0})
    // 移动缩放控制
    const [moveable, setMoveable] = useState()
    const moveableRef = useRef()
    const [zoom, setZoom] = useState(1)
    const zoomRef = useRef(1)
    const minZoomRef = useRef(.5)
    const maxZoomRef = useRef(10)
    const paintModeBackup = useRef() // paintMode备份，用于取消移动后恢复操作
    const interactiveModeBackup = useRef('add') // interactiveMode备份，用于取消移动后恢复操作
    useEffect(() => {
        moveableRef.current = moveable
        if (!moveable) {
            setPaintMode(paintModeBackup.current)
            setInteractiveMode(interactiveModeBackup.current)
        } else {
            paintModeBackup.current = paintModeRef.current
            interactiveModeBackup.current = interactiveModeRef.current
            setPaintMode()
            setInteractiveMode()
        }
    }, [moveable])
    useEffect(() => {
        zoomRef.current = zoom
    }, [zoom])
    const zoomOut = () => {
        setZoom(pre => {
            pre -= .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }
    const zoomIn = () => {
        setZoom(pre => {
            pre += .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }

    return (
        <div>
            <div className={styles.Header}>
                <ActionGroup items={interactiveItems} value={interactiveMode} onChange={value => setInteractiveMode(value)} />
                <ActionGroup items={paintItems} value={paintMode} onChange={value => setPaintMode(value)} />
                <ActionGroup items={actionItems} />
            </div>
            <div className={styles.Content}>
                <div ref={canvasWrapRef}>
                    {inited &&
                    <MattingOptCanvas
                        ref={canvasRef}
                        inputImageUrl={inputImageUrl}
                        whiteMaskUrl={whiteMaskUrl}
                        blueMaskBase64={blueMaskBase64}
                        setBlueMaskBase64={setBlueMaskBase64}
                        setBlendedBlueMaskBase64={setBlendedBlueMaskBase64}
                        // inputImageBase64={inputImageBase64}
                        // setWhiteMaskBase64={setWhiteMaskBase64}
                        displayScale={displayScale}
                        displayWidth={displayWidth}
                        displayHeight={displayHeight}
                        moveable={moveable}
                        zoom={zoom}
                        minZoom={minZoomRef.current}
                        maxZoom={maxZoomRef.current}
                        setZoom={setZoom}
                        setViewportOffset={setViewportOffset}
                        interactiveMode={interactiveMode}
                        // setInteractiveMode={setInteractiveMode}
                        paintMode={paintMode}
                        paintSize={paintSize}
                        setPaintSize={setPaintSize}
                        eraseSize={eraseSize}
                        setEraseSize={setEraseSize}
                        addUndo={addUndo}
                        addRedo={addRedo}
                        clearRedo={clearRedo}
                        setUpdated={setUpdated}
                        imageType={props.imageType}
                        aiInteractiveNpy={props.aiInteractiveNpy}
                    />}
                    <span className={styles.Tips}>原图</span>
                </div>
                <div>
                    {inited &&
                    <MattingOptRightCanvas
                        viewportOffset={viewportOffset}
                        displayScale={displayScale}
                        displayWidth={displayWidth}
                        displayHeight={displayHeight}
                        inputImageUrl={inputImageUrl}
                        inputImageBase64={inputImageBase64}
                        blendedBlueMaskBase64={blendedBlueMaskBase64}
                    />}
                    <span className={styles.Tips}>预览</span>
                </div>
                <DragAndScale
                    moveable={moveable}
                    zoom={zoom}
                    zoomOut={zoomOut}
                    zoomIn={zoomIn}
                    onMoveableReverse={() => setMoveable(pre => !pre)}
                    onReset={() => canvasRef.current.resetCanvas()}
                />
            </div>
        </div>
    )
})

export default MattingOpt