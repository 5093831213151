
import axios from 'axios'
import API from "@api/api";
import { message } from 'antd';
import { isMobile } from 'react-device-detect';
import { IMAGE_TYPE, CLOTH_TYPE, TOPIC } from "@utils/CONST"
import { fabric } from 'fabric';
import Decimal from 'decimal.js'
import JSZip from 'jszip'
import { saveAs } from "file-saver";

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

function getTrimmedImageBySize(base64, minX, minY, maxX, maxY) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const rectWidth = Decimal.sub(maxX, minX).add(1).toNumber();
      const rectHeight = Decimal.sub(maxY, minY).add(1).toNumber();
      const resultCanvas = document.createElement('canvas');
      resultCanvas.width = rectWidth;
      resultCanvas.height = rectHeight;
      const resultCtx = resultCanvas.getContext('2d');
      resultCtx.drawImage(canvas, minX, minY, rectWidth, rectHeight, 0, 0, rectWidth, rectHeight);

      const resultBase64 = resultCanvas.toDataURL();
      resolve(resultBase64)
    };
    img.onerror = reject;
    img.src = base64;
  });
}

async function mergeBodyAndClothMask(body, cloth) {
  const bodyImg = await loadImageUrlByFabric(body)
  const clothImg = await loadImageUrlByFabric(cloth)
  const tempCanvas = new fabric.StaticCanvas(null, {
    width: bodyImg.width,
    height: bodyImg.height
  })
  // 保留两个图层中最亮的像素
  clothImg.set({ globalCompositeOperation: "lighten", })
  tempCanvas.add(bodyImg).add(clothImg)
  return tempCanvas.toDataURL({ format: 'png', multiplier: 1 })
}

function getTrimmedSizeByBlackMask(base64) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;
      let checkFlag = false;
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const index = (y * canvas.width + x) * 4;
          if (data[index] > 0) {
            checkFlag = true;
            minX = Decimal.min(minX, x);
            minY = Decimal.min(minY, y);
            maxX = Decimal.max(maxX, x);
            maxY = Decimal.max(maxY, y);
          }
        }
      }
      // console.timeEnd('=========getTrimmedImageBase64==========')
      if (checkFlag) {
        resolve({
          originSize: {
            width: img.width,
            height: img.height
          },
          leftTop: { x: minX.toNumber(), y: minY.toNumber() },
          rightBottom: { x: maxX.toNumber(), y: maxY.toNumber() },
        });
      } else { //canvas中没有mask
        resolve({
          originSize: {
            width: img.width,
            height: img.height,
          },
          leftTop: { x: 0, y: 0 },
          rightBottom: { x: 0, y: 0 },
        })
      }
    };
    img.onerror = reject;
    img.src = base64;
  });
}

// 抠出图像内容边廓矩形
function getTrimmedImageBase64(base64) {
  // console.time('=========getTrimmedImageBase64==========')

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;
      let checkFlag = false;
      for (let y = 0; y < canvas.height; y++) {
        for (let x = 0; x < canvas.width; x++) {
          const index = (y * canvas.width + x) * 4;
          if (data[index + 3] > 0) {
            checkFlag = true;
            minX = Decimal.min(minX, x);
            minY = Decimal.min(minY, y);
            maxX = Decimal.max(maxX, x);
            maxY = Decimal.max(maxY, y);
          }
        }
      }
      const rectWidth = Decimal.sub(maxX, minX).add(1).toNumber();
      const rectHeight = Decimal.sub(maxY, minY).add(1).toNumber();
      const resultCanvas = document.createElement('canvas');
      resultCanvas.width = rectWidth;
      resultCanvas.height = rectHeight;
      const resultCtx = resultCanvas.getContext('2d');
      resultCtx.drawImage(canvas, minX, minY, rectWidth, rectHeight, 0, 0, rectWidth, rectHeight);

      const resultBase64 = resultCanvas.toDataURL();


      // console.timeEnd('=========getTrimmedImageBase64==========')
      if (checkFlag) {
        resolve({
          originSize: {
            width: img.width,
            height: img.height
          },
          leftTop: { x: minX, y: minY },
          rightBottom: { x: maxX, y: maxY },
          base64: resultBase64
        });
      } else { //canvas中没有mask
        resolve({
          originSize: {
            width: img.width,
            height: img.height,
          },
          leftTop: { x: 0, y: 0 },
          rightBottom: { x: 0, y: 0 },
          base64: resultBase64
        })
      }
    };
    img.onerror = reject;
    img.src = base64;
  });
}

// 将透明背景，加任意颜色内容转为黑底白色
function convertBase64Image(base64Image) {
  // console.time('=========convertBase64Image==========')
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      // 创建 Canvas 元素
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      // 在 Canvas 上绘制图像
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      // 获取像素数据
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // 遍历像素数据，根据透明度设置颜色
      for (let i = 0; i < data.length; i += 4) {
        const alpha = data[i + 3];
        const color = alpha < 0.8 * 255 ? 0 : 255;
        data[i] = color;
        data[i + 1] = color;
        data[i + 2] = color;
        data[i + 3] = 255;
      }

      // 将处理后的像素数据绘制到 Canvas 上
      ctx.putImageData(imageData, 0, 0);

      // 将 Canvas 转换为 base64 编码的图片
      const newBase64Image = canvas.toDataURL('image/png');

      // console.timeEnd('=========convertBase64Image==========')
      resolve(newBase64Image);
    };
    img.onerror = reject;
    img.src = base64Image;
  });
}

// 反转透明的部分到指定颜色，其他颜色置为透明
function reverseTransparent(base64Image, color) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data
      for (let i = 0; i < imageData.data.length; i += 4) {
        if (data[i + 3] == 0) {
          data[i] = color[0]
          data[i + 1] = color[1]
          data[i + 2] = color[2]
          data[i + 3] = color[3] || 255
        } else {
          data[i] = 0
          data[i + 1] = 0
          data[i + 2] = 0
          data[i + 3] = 0
        }
      }

      ctx.putImageData(imageData, 0, 0);
      const modifiedBase64 = canvas.toDataURL('image/png');

      resolve(modifiedBase64);
    };

    img.onerror = () => {
      reject(Error('Failed to load the image.'));
    };

    img.src = base64Image;
  });
}

function setOpaquePixels(base64Image) {
  // console.time('=========setOpaquePixels==========')
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = 255;
        imageData.data[i + 1] = 255;
        imageData.data[i + 2] = 255;
        imageData.data[i + 3] = imageData.data[i + 3] / 204 * 255;
      }

      ctx.putImageData(imageData, 0, 0);
      const modifiedBase64 = canvas.toDataURL('image/png');

      // console.timeEnd('=========setOpaquePixels==========')
      resolve(modifiedBase64);
    };

    img.onerror = () => {
      reject(Error('Failed to load the image.'));
    };

    img.src = base64Image;
  });
}

// 原图加mask去除前景
function getForegroundImage(samOrign_1024, samClickMask) {
  // console.time('=========getForegroundImage==========')
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  function loadImage(src) {
    // console.time('=========loadImage==========')
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // 允许跨域
      img.onload = () => {
        // console.timeEnd('=========loadImage==========')
        resolve(img)
      };
      img.onerror = reject;
      img.src = src;
    });
  }
  return new Promise((resolve, reject) => {
    Promise.all([
      loadImage(samOrign_1024),
      loadImage(samClickMask)
    ]).then(([originalImg, maskImg]) => {
      canvas.width = originalImg.width;
      canvas.height = originalImg.height;

      ctx.drawImage(originalImg, 0, 0);

      ctx.globalCompositeOperation = 'destination-in';

      ctx.drawImage(maskImg, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const maskData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      for (let i = 0; i < imageData.data.length; i += 4) {
        const a = maskData.data[i + 3];
        if (a < 0) {
          imageData.data[i + 3] = 255; // 设置透明像素为不透明
        }
      }

      const base64 = canvas.toDataURL('image/png');

      // console.timeEnd('=========getForegroundImage==========')

      resolve(base64);
    });
  });
}

function resizeImage(base64, width, height) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const resizedBase64 = canvas.toDataURL('image/png');

      resolve(resizedBase64);
    };
    img.onerror = reject;
    img.src = base64;
  });
}

// 将黑白mask转为蓝色透明
function convertWhiteImageToBlue(base64Data) {
  // console.time('=========convertWhiteImageToBlue==========')
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      for (let i = 0; i < pixels.length; i += 4) {
        const red = pixels[i];
        const green = pixels[i + 1];
        const blue = pixels[i + 2];
        // Set non-black pixels to rgba(30, 144, 255, 0.8)
        pixels[i] = 30;
        pixels[i + 1] = 144;
        pixels[i + 2] = 255;
        pixels[i + 3] = red / 255 * 204;
      }

      ctx.putImageData(imageData, 0, 0);
      const convertedData = canvas.toDataURL();

      // console.timeEnd('=========convertWhiteImageToBlue==========')

      resolve(convertedData);
    };

    img.onerror = (error) => {
      reject(error);
    };
    img.src = base64Data;
  });
}

// 将蓝色透明转为黑白mask
function convertBlueImageToWhite(base64Data) {
  // console.time('=========convertWhiteImageToBlue==========')
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous"
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const pixels = imageData.data;

      for (let i = 0; i < pixels.length; i += 4) {
        const red = pixels[i];
        const green = pixels[i + 1];
        const blue = pixels[i + 2];
        const alpha = pixels[i + 3];
        // Set non-black pixels to rgba(30, 144, 255, 0.8)
        pixels[i] = alpha / 204 * 255;
        pixels[i + 1] = alpha / 204 * 255;
        pixels[i + 2] = alpha / 204 * 255;
        pixels[i + 3] = 255;
      }

      ctx.putImageData(imageData, 0, 0);
      const convertedData = canvas.toDataURL();

      // console.timeEnd('=========convertWhiteImageToBlue==========')

      resolve(convertedData);
    };

    img.onerror = reject;
    img.src = base64Data;
  });
}

// 将图片url转成base64
async function urlToBase64(url) {
  // console.time('=========urlToBase64==========')
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      // console.timeEnd('=========urlToBase64==========')
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

// 将url转file对象
async function urlToFile(url) {
  // console.time('=========urlToFile==========')
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], 'image.png', { type: 'image/png' })
}

// 将url转blob对象
async function urlToBlob(url) {
  // console.time('=========urlToFile==========')
  const response = await fetch(url);
  const blob = await response.blob();
  return blob
}

// blob对象转base64
async function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      // console.timeEnd('=========urlToBase64==========')
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
}

// blob对象转file对象
function blobToFile(blob, filename = 'image.png', type = 'image/png') {
  const url = URL.createObjectURL(blob)
  const file = new File([blob], filename, { type: type })
  file.uid = url.split('/').pop()
  return file
}

// 将base64转 file 对象
function convertBase64ToFile(base64Image, fileName) {
  // console.time('=========convertBase64ToFile==========')
  const base64 = base64Image.split(',')[1];
  const byteArray = atob(base64);
  const arrayBuffer = new ArrayBuffer(byteArray.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteArray.length; i++) {
    uint8Array[i] = byteArray.charCodeAt(i);
  }
  const blob = new Blob([arrayBuffer], { type: 'image/png' });
  const file = new File([blob], fileName, { type: 'image/png' });

  // console.timeEnd('=========convertBase64ToFile==========')
  return file;
}

// 将所有带透明度的白色转化为不带透明度
function convertTransparentWhiteToOpaque(base64Image) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = base64Image;

    img.onload = () => {
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let data = imageData.data;

      // 记录变化的像素数量，用于调试
      let changedPixels = 0;

      for (let i = 0; i < data.length; i += 4) {
        let r = data[i];
        let g = data[i + 1];
        let b = data[i + 2];
        let a = data[i + 3];

        // 如果像素不是黑色
        if (r !== 0 || g !== 0 || b !== 0) {
          data[i] = 255;     // 设置 R 为 255
          data[i + 1] = 255; // 设置 G 为 255
          data[i + 2] = 255; // 设置 B 为 255
          data[i + 3] = 255; // 设置 A 为 255
          changedPixels++;
        }
      }

      // 更新 canvas 上的图像数据
      ctx.putImageData(imageData, 0, 0);

      // 将 canvas 转换为 base64 格式
      let newBase64Image = canvas.toDataURL();

      // 输出调试信息
      console.log(`Changed ${changedPixels} pixels`);

      resolve(newBase64Image);
    };

    img.onerror = (error) => {
      reject(`Image load error: ${error}`);
    };
  });
}







// 将图片某种颜色转化为另一种颜色
function replaceColor(base64, fromColor, toColor) {
  // console.time('=========replaceColor==========')
  return new Promise(resolve => {
    const image = new Image();
    image.crossOrigin = "anonymous"
    image.onload = function () {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);

      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // 将颜色字符串转换为 RGBA 格式，并添加透明度值
      const fromRGBA = `rgba(${fromColor.r}, ${fromColor.g}, ${fromColor.b}, ${fromColor.a})`;
      const toRGBA = `rgba(${toColor.r}, ${toColor.g}, ${toColor.b}, ${toColor.a})`;

      for (let i = 0; i < data.length; i += 4) {
        // 将原颜色替换为指定颜色
        if (data[i] === fromColor.r && data[i + 1] === fromColor.g && data[i + 2] === fromColor.b) {
          data[i] = toColor.r;
          data[i + 1] = toColor.g;
          data[i + 2] = toColor.b;

          // 根据 toColor 的透明度调整新的透明度值
          data[i + 3] = Math.round(data[i + 3] * toColor.a / fromColor.a);
        }
      }

      context.putImageData(imageData, 0, 0);

      const newBase64 = canvas.toDataURL();

      // console.timeEnd('=========replaceColor==========')

      resolve(newBase64);
    };
    image.src = base64;
  });
}

function downloadFileWithToken(fileUrl, fileName) {
  if (!fileName) fileName = fileUrl.replace(/.+\/([^\/]+$)/, '$1')
  return axios({
    method: 'get',
    url: fileUrl,
    headers: {
      'Ps-Auth-Token': localStorage.getItem('token')
    },
    responseType: 'blob' // 希望后端返回Blob类型属性
  }).then(res => {
    const blob = new Blob([res.data], {
      // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
      type: 'application/octet-stream'
      // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
    })
    let link = document.createElement('a')
    let body = document.querySelector('body')
    link.href = window.URL.createObjectURL(blob) // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面

    link.download = fileName || 'download' // 自己制定下载文件的文件名
    // 兼容火狐浏览器
    link.style.display = 'none'	// 让这个a标签不可见
    body.appendChild(link)
    link.click()		// 创建了新的a标签之后模拟点击事件，开始传输文件
    body.removeChild(link)	// 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
    window.URL.revokeObjectURL(link.href)	// 移除之前使用createObjectURL创建的URL，垃圾回收
  })
}

// 手机号验证
function validateContact(contact) {
  const phoneReg = /^1[3456789]\d{9}$/;

  if (phoneReg.test(contact)) {
    return true;
  }
  //   if (emailReg.test(contact)) {
  //     return true;
  //   }
  // 不是手机号也不是邮箱地址
  return false;
}

// 邮箱验证
function validateContact2(contact) {
  const emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/

  if (emailReg.test(contact)) {
    return true;
  }
  return false;
}

function downUrl(fileUrl, imageType, isHD) { //第二个参数是埋点需要
  useroplog(imageType, 'function', 'download', isHD ? { 'downUrl_HD': fileUrl } : { 'downUrl': fileUrl })


  function isBase64(src) {
    // 检查是否以 data: 开头
    return /^data:image\/[a-z]+;base64,/.test(src);
  }
  if (isBase64(fileUrl)) {
    let link = document.createElement('a')
    link.href = fileUrl
    link.download = `file_${new Date().getTime()}.png` // 自己制定下载文件的文件名
    // 兼容火狐浏览器
    link.style.display = 'none'	// 让这个a标签不可见
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link);
    return
  }
  return axios({
    method: 'get',
    url: fileUrl,
    responseType: 'blob'
  }).then(res => {
    // console.log(fileUrl);
    const fileName = fileUrl.split('?')[0].split('/').pop();
    const fileExtension = fileName.split('.').pop(); // 获取文件类型后缀

    const blob = new Blob([res.data], {
      type: `application/octet-stream.${fileExtension}` // 添加文件类型后缀
    });

    let link = document.createElement('a');
    let body = document.querySelector('body');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName || 'download';
    link.style.display = 'none';
    body.appendChild(link);
    link.click();
    body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  });
}

// 下载压缩包
async function downImageZip(imageList) {
  const request = (url) => {
    return axios.get(url, { responseType: 'arraybuffer' })
  }
  let zip = new JSZip()
  for (let i = 0; i < imageList.length; i++) {
    const url = imageList[i]
    try {
      const fileExtension = url.split('.').pop()
      const res = await request(url)
      zip.file(`image${i + 1}.${fileExtension}`, res.data, { binary: true })
    } catch (err) {
      console.error(err)
    }
  }
  const content = await zip.generateAsync({ type: "blob" })
  saveAs(content, `image_extraction_${new Date().getTime()}.zip`)
}

// 延迟执行函数
function delayFunc(timeout = 3000) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, timeout)
  })
}

//输出图像的长短边
function getLongestSideLength(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      const longestSide = Math.max(width, height);
      const shortestSide = Math.min(width, height);

      resolve({ longestSide, shortestSide });
    };

    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };

    img.src = url;
  });
}

// 裁剪图片
function cropImageBase64(base64, x, y, width, height) {
  // console.time('=========cropImageBase64==========')
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous"
    image.onload = function () {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = height;

      context.drawImage(image, x, y, width, height, 0, 0, width, height);

      const croppedBase64 = canvas.toDataURL('image/png');

      // console.timeEnd('=========cropImageBase64==========')

      resolve(croppedBase64);
    };
    image.onerror = function (error) {
      reject(error);
    };
    image.src = base64;
  });
}

// 获取视频宽高
function getVideoDimensions(videoPath) {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.addEventListener('loadedmetadata', () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      resolve({ width, height });
    });

    video.addEventListener('error', () => {
      reject(new Error('无法加载视频'));
    });

    video.src = videoPath;
    video.load()
  })
}

// 获取图片宽高
function getImageDimensions(base64Image) {
  // console.time('=========getImageDimensions==========')
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous'
    img.onload = function () {
      const { width, height } = this;

      // console.timeEnd('=========getImageDimensions==========')

      resolve({ width, height });
    };
    img.onerror = function () {
      reject(new Error('Failed to load image.'));
    };
    img.src = (base64Image instanceof File) ? URL.createObjectURL(base64Image) : base64Image
  });
}

function convertNonTransparentToColor(base64, color) {
  // console.time('=========convertNonTransparentToColor==========')
  return new Promise(function (resolve, reject) {
    var image = new Image();
    image.src = base64;

    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');

    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);

      var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      var data = imageData.data;

      for (var i = 0; i < data.length; i += 4) {
        var alpha = data[i + 3];

        if (alpha !== 0) {
          data[i] = color[0];     // Red component
          data[i + 1] = color[1]; // Green component
          data[i + 2] = color[2]; // Blue component
        }
      }

      context.putImageData(imageData, 0, 0);

      var convertedBase64 = canvas.toDataURL();

      // console.timeEnd('=========convertNonTransparentToColor==========')

      resolve(convertedBase64);
    };

    image.onerror = function () {
      reject(new Error('Failed to load the image.'));
    };
  });
}

// file对象转base64
function convertFileToBase64(file) {
  // console.time('=========convertFileToBase64==========')
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      // console.timeEnd('=========convertFileToBase64==========')
      resolve(e.target.result);
    };
    fileReader.readAsDataURL(file); // file 或 raw
    fileReader.onerror = () => {
      reject(new Error('文件流异常'));
    };
  })
}

// 通过阿里云resize图片
async function resizeImageFileMaxByAliyunBy(imageFile, max) {
  try {
    if (!imageFile instanceof File) throw new Error('Wrong File type')
    if (imageFile.size / 1024 / 1024 > 30) throw new Error('图片应该 ≤ 30MB')
    const imageBase64 = await convertFileToBase64(imageFile)
    const imgSize = await getImageDimensions(imageBase64)
    let width = imgSize.width;
    let height = imgSize.height;
    let useOrigin = true
    if (Math.max(width, height) > max) {
      useOrigin = false
      const aspectRatio = width / height;
      if (width > height) {
        width = max;
        height = Math.floor(width / aspectRatio);
      } else {
        height = max;
        width = Math.floor(height * aspectRatio);
      }
    }
    let formdata1 = new FormData
    formdata1.append('file', imageFile)
    const res = await API.uploadImage(formdata1)
    if (res?.code != 0) throw new Error(res?.message)
    const imageUrl = res?.data?.filename
    if (useOrigin) {
      return {
        success: true,
        file: imageFile,
        url: imageUrl,
        base64: imageBase64,
        width,
        height
      }
    } else {
      const newImgUrl = imageUrl + `?x-oss-process=image/resize,w_${width},h_${height},m_fixed`
      const blob = await urlToBlob(newImgUrl)
      const newImgBase64 = await blobToBase64(blob)
      const newImgFile = blobToFile(blob, imageFile.name, imageFile.type)
      return {
        success: true,
        file: newImgFile,
        url: newImgUrl,
        base64: newImgBase64,
        width,
        height
      }
    }
  } catch (err) {
    return {
      success: false,
      message: err.message
    }
  }
}

// 超分下载img，不带其他参数（用于AI编辑画质升级、普通下载，不消耗虹豆）
const hdDownloadFunc = async ({
  imgPath = '',
  scale = 1,
  imgSrc = '',
  maskCloth = '',
  moteImg = '',
  colorMask = '',
  bodyMask = '',
  imageH = false
}, setLoading = () => { }) => {
  const getaisuperresolutionResult = (id) => {
    return new Promise((resolve, reject) => {
      const loopFetch = () => {
        axios.post(API.aisuperresolutionPredictionUrl + id, {
          imageH
        }, {
          headers: {
            'Content-type': 'application/json',
            'Ps-Auth-Token': localStorage.getItem('token')
          }
        }).then(res => {
          if ((res.data.code == 0 && res.data.data.status == 'succeeded')) {
            resolve(res.data.data.output)
          } else if (res.data.code == 0 && res.data.data.status == 'failed') {
            reject(new Error(res.data.data.errcode))
          } else {
            let timeout = setTimeout(() => {
              clearTimeout(timeout)
              loopFetch()
            }, 400)
          }
        }).catch(err => {
          reject(err)
        })
      }
      loopFetch()
    })
  }
  function isBase64(src) {
    // 检查是否以 data: 开头
    return /^data:image\/[a-z]+;base64,/.test(src);
  }
  // 超分
  setLoading(true)
  try {
    if (isBase64(imgPath)) {
      let formdata = new FormData
      formdata.append('file', convertBase64ToFile(imgPath, 'image.png'))
      const res = await API.uploadImage(formdata)
      if (res.code != 0) throw new Error(res.message)
      imgPath = res.data.filename
    }
    const res = await API.aisuperresolutionPrediction({
      img_path: imgPath,
      img_src: imgSrc,
      mask_cloth: maskCloth,
      mote_img: moteImg,
      color_mask: colorMask,
      body_mask: bodyMask,
      imageH,
      aiEdit: 1,
      upscale: scale,
    })
    if (res.code != 0) throw new Error(res.message)
    const output = await getaisuperresolutionResult(res.data)
    return {
      algoId: res.data,
      output: output
    }
  } catch (err) {
    console.error(err)
    return false
  } finally {
    setLoading(false)
  }
}

const hdDownload4VideoFunc = async ({ videoPath = '', scale = 1 }, setLoading = () => { }) => {
  const getaisuperresolutionResult = (id) => {
    return new Promise((resolve, reject) => {
      const loopFetch = () => {
        axios.post(API.aisuperresolution4VideoPredictionUrl + id, {}, {
          headers: {
            'Content-type': 'application/json',
            'Ps-Auth-Token': localStorage.getItem('token')
          }
        }).then(res => {
          if ((res.data.code == 0 && res.data.data.status == 'succeeded')) {
            resolve(res.data.data.output)
          } else if (res.data.code == 0 && res.data.data.status == 'failed') {
            reject(new Error(res.data.data.errcode))
          } else {
            let timeout = setTimeout(() => {
              clearTimeout(timeout)
              loopFetch()
            }, 400)
          }
        }).catch(err => {
          reject(err)
        })
      }
      loopFetch()
    })
  }
  // 超分
  setLoading(true)
  try {
    const res = await API.aisuperresolution4VideoPrediction({
      v_url: videoPath,
      sr_scale: scale,
    })
    if (res.code != 0) throw new Error(res.message)
    const output = await getaisuperresolutionResult(res.data)
    return {
      algoId: res.data,
      output: output
    }
  } catch (err) {
    console.error(err)
    return false
  } finally {
    setLoading(false)
  }
}

const getHDParams = async (taskId, imageType, topic) => {
  let res = await API.commonDownload({ taskId })
  if (res?.code != 0) throw new Error(res?.message)
  let imgPath = res.data
  let maskCloth, imgSrc, moteImg, colorMask
  let imageH = false // sr走imageH
  let bodyMask = ''
  if (imageType == IMAGE_TYPE.COMMODITY_VIDEO || imageType == IMAGE_TYPE.MODEL_VIDEO) {
    // do nothing
  } else if (imageType == IMAGE_TYPE.COMMODITY) {
    // get taskinfo
    res = await API.getTaskInfo({ taskId })
    if (res?.code != 0) throw new Error(res?.message)
    const taskInfo = res.data

    const outputs = taskInfo.inpaint?.outputs
    maskCloth = outputs.find(item => /out-gods-aligned_mask-goods/.test(item.image))?.image || outputs[1]?.image
    imgSrc = outputs.find(item => /out-gods-aligned_goods-goods/.test(item.image))?.image || outputs[2].image
  } else {
    // get taskinfo
    res = await API.getTaskInfo({ taskId })
    if (res?.code != 0) throw new Error(res?.message)
    const taskInfo = res.data

    const outputs = taskInfo.inpaint?.outputs
    const input = taskInfo.inpaint?.input


    if (imageType == IMAGE_TYPE.MODEL) {
      const isSameStyle = input?.same_pattern == 1 // 拍同款
      if (isSameStyle) maskCloth = outputs.find(item => /out-clothsrc-/.test(item.image))?.image // 来自outputs
      else maskCloth = input?.mask_path_cloth // 服装参数 // 来自prediction
      // imageH = /_rongrong(id)?/.test(input.mote_style) // 溶溶定制，sr走imageH
      if (topic == TOPIC.AIMODEL_INPAINT || topic == TOPIC.AIBKGMODEL_INPAINT) {
        bodyMask = outputs.find(item => /out-bodysrc-mote/.test(item.image))?.image
        if (res.data?.inpaint?.input?.keepPose == 1) {
          maskCloth = bodyMask
        }
        imgPath = res.data?.inpaint?.outputs[1]?.image
        imageH = true
      }
    } else if (imageType == IMAGE_TYPE.DRESSFORM) { // 人台的maskCloth需要取自outputs by ligd 202405231559
      maskCloth = outputs.find(item => /return-clothmask-mote/.test(item.image))?.image || outputs[1]?.image // 来自outputs
    } else {

      maskCloth = outputs.find(item => /out-tryon-2step-bodymask/.test(item.image))?.image || outputs[1]?.image // clothes
    }

    if (imageType == IMAGE_TYPE.CLOTHES) {
      imgSrc = ''
      // 以下两个参数，仅在keepPose=1 && 自定义模特，时需要传
      if (input.keepPose == 1) {
        moteImg = input.mote_img
        colorMask = input.color_mask
      }
      if (input.keepPose == 1 && input.keepBkg == 1) {
        imgSrc = outputs.find(item => /out-tryon-1step-resimage/.test(item.image))?.image
        bodyMask = outputs.find(item => /out-tryon-dmotemask/.test(item.image))?.image
        maskCloth = bodyMask
        imgPath = imgSrc
      } else {
        const modelInpaint = await API.getInpaintPredictionById(taskInfo.inpaint?.attr?.modelInpaintId)
        imgPath = modelInpaint?.data?.output[1]
        imgSrc = modelInpaint?.data?.output[2]
        maskCloth = modelInpaint?.data?.output[3]
        bodyMask = modelInpaint?.data?.output[4]
        imageH = true
      }
    } else if (imageType == IMAGE_TYPE.DRESSFORM) {
      imgSrc = outputs.find(item => /return-src-img-mote/.test(item.image))?.image || outputs[2]?.image // 来自outputs
    } else {
      const isSameStyle = input?.same_pattern == 1 // 拍同款
      if (isSameStyle) imgSrc = outputs.find(item => /out-imgsrc-/.test(item.image))?.image
      else imgSrc = input?.img_path // 来自prediction
    }
  }
  return {
    img_path: imgPath, // 生成图
    img_src: imgSrc || '', // 原始图
    mask_cloth: maskCloth || '',
    mote_img: moteImg || '',
    color_mask: colorMask || '',
    body_mask: bodyMask || '',
    imageH,
  }
}

// 高清预览
const toHDPreview = async (taskId, imageId, imageType, topic) => {
  try {
    const params = await getHDParams(taskId, imageType, topic)
    let res = await API.aisuperresolutionPrediction({
      ...params,
      upscale: 2,
      aiEdit: 0,
    })
    if (res?.code != 0) throw new Error(res?.message)
    // console.log('algoid', res)
    const hdAlgoId = res.data
    res = await API.setHDImage({
      taskId, imageId, hdAlgoId
    })
    if (res?.code != 0) throw new Error(res?.message)
    return true
  } catch (err) {
    message.error(err.message)
  }
}

// video tryon全身图片检测
const videoTryonImageCheck = async (imageUrl, isFront = 1) => {
  try {
    const res = await API.videoTryonImageCheck({
      imageUrl: imageUrl,
      isFront: isFront ? 1 : 0
    })
    if (res?.code != 0) throw new Error(res.message)
    return res?.data
  } catch (err) {
    message.warning(err.message)
  }
}

/**
 * 返回在容器内图片可以显示的最大宽高
 * @param {number} imgWidth - 图片宽度
 * @param {number} imgHeight - 图片高度
 * @param {number} containerWidth - 容器宽度
 * @param {number} containerHeight - 容器高度
 * @returns 
 */
const getImgMaxSizeInContainer = (imgWidth, imgHeight, containerWidth, containerHeight) => {
  if (!containerWidth || !containerHeight) return { width: 0, height: 0 }
  if (!imgWidth || !imgHeight) return { width: 0, height: 0 }
  const containerAspectRatio = Decimal.div(containerWidth, containerHeight)
  const imageAspectRatio = Decimal.div(imgWidth, imgHeight)
  let maxWidth, maxHeight;
  if (containerAspectRatio.greaterThan(imageAspectRatio)) {
    // 容器的宽高比较大，以容器的高度为基准计算最大宽度
    maxHeight = containerHeight;
    maxWidth = Decimal.mul(maxHeight, imageAspectRatio).toNumber();
  } else {
    // 图片的宽高比较大，以容器的宽度为基准计算最大高度
    maxWidth = containerWidth;
    maxHeight = Decimal.div(maxWidth, imageAspectRatio).toNumber();
  }

  return { width: maxWidth, height: maxHeight }
}

// 构图优化时，浏览器若缩放，将结果图resize到正常尺寸
function resizeModelScale(base64Image, scaleFactor) {
  // console.time('=========resizeModelScale==========')
  if (scaleFactor == 1) return Promise.resolve(base64Image)
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const width = Math.round(img.width / scaleFactor);
      const height = Math.round(img.height / scaleFactor);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);
      const shrunkBase64 = canvas.toDataURL('image/webp', .95);

      // console.timeEnd('=========resizeModelScale==========')

      resolve(shrunkBase64);
    };
    img.onerror = function (error) {
      reject(error);
    };
    img.src = base64Image;
  });
}

/**
 * 转换图片颜色
 * @param {*} base64 
 * @param  {Color[]} colors - 转换的颜色数组，以两个为一对进行转换 
 */
const convertImgColor = (base64, ...colors) => {
  // console.time('=========convertImgColor==========')
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'anonymous'
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = image.width;
      canvas.height = image.height;
      ctx.drawImage(image, 0, 0);
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const red = data[i];
        const green = data[i + 1];
        const blue = data[i + 2];
        const alpha = data[i + 3];

        // console.log('color？', red, green, blue, alpha)
        // 判断像素颜色是否与原始颜色相等
        for (let colorGroup of colors) {
          if (colorGroup.length != 2) return reject(new Error('参数错误'))
          const [fromColor, toColor] = colorGroup
          if (fromColor.length < 3 || toColor.length < 3) return reject(new Error('参数错误'))
          if (
            (red == fromColor[0] || fromColor[0] == '*') &&
            (green == fromColor[1] || fromColor[1] == '*') &&
            (blue == fromColor[2] || fromColor[2] == '*') &&
            (fromColor.length < 4 || (fromColor.length > 3 && (alpha == fromColor[3] || fromColor[3] == '*')))
          ) {
            // 修改像素颜色为新颜色
            data[i] = toColor[0];
            data[i + 1] = toColor[1];
            data[i + 2] = toColor[2];
            if (fromColor.length > 3) data[i + 3] = toColor[3];
            break // 每组像素点仅改变一次，否则会导致重复覆盖
          }
        }
      }

      ctx.putImageData(imageData, 0, 0);

      // console.timeEnd('=========convertImgColor==========')

      resolve(canvas.toDataURL());
    }
    image.onerror = reject
    image.src = base64;
  })
}

function addVersionParamToURL(url, param, paramValue) {
  try {
    const urlObject = new URL(getImgUrlWithWebp(url));
    const searchParams = new URLSearchParams(urlObject.search);

    if (searchParams.has(param)) {
      // 如果已经存在版本号参数，则替换现有的版本号值
      searchParams.set(param, paramValue);
    } else {
      // 如果不存在版本号参数，则添加版本号参数
      searchParams.append(param, paramValue);
    }

    urlObject.search = searchParams.toString();
    return urlObject.toString();
  } catch (err) {
    console.error(err)
    return ""
  }
}

const parseErrorCode = (errorStr) => {
  const DEFAULT_ERROR_MESSAGE = '图片不合格，请重试'
  const ERROR_MESSAGE = {
    '71000': '点不能为空，请拖拽位置',
    '71001': '区域不能为空，请锁定区域',
    '71002': '资源错误，请刷新页面',
    '71003': '资源错误，请刷新页面',
    '71004': '图片不合格，请重试',
    '71005': '任务排队中，请稍后重试',
    '71006': '资源错误，请刷新页面',
    '71007': '图片不合格，请重试',
    '71008': '请选择预置素材库',
    '71009': '图片资源获取失败，请稍后重试',
    '71010': '资源错误，请刷新页面',
    '71011': '资源错误，请刷新页面',
    '71105': 'AI试衣不支持',
    '71106': '模特图不合格',
    '72001': '商品类型不支持，请更换服装图',
    '422': '请求参数错误，请刷新重试',
    '502': '服务繁忙，请稍后重试',
    '503': '服务繁忙，请稍后重试',
    '-61201': '服务繁忙，请稍后重试',
    '61449': '图片不合格，请重试',
  }
  const res = /\[(-?\d+)\]|(-?\d+)/.exec(errorStr)
  const code = res ? res[1] || res[2] : '502'
  const message = ERROR_MESSAGE[code] ? ERROR_MESSAGE[code] : DEFAULT_ERROR_MESSAGE
  return {
    code: code,
    message: code ? `[${code}] ${message}` : message
  }
}

// 解析 Base64 是否包含透明通道
function hasAlphaChannel(base64String) {
  return new Promise(function (resolve, reject) {
    var img = new Image();
    img.crossOrigin = 'anonymous'; // 设置跨域属性，避免污染 canvas
    img.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      var context = canvas.getContext('2d');
      context.drawImage(img, 0, 0);

      var imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      var data = imageData.data;

      for (var i = 3; i < data.length; i += 4) {
        if (data[i] < 255) {
          resolve(true); // 存在透明通道
          return;
        }
      }
      resolve(false); // 不存在透明通道
    };
    img.onerror = function () {
      reject(new Error('Failed to load image'));
    };
    img.src = base64String;
  });
}

// 判断file文件比例不超过2：1
// 图片大小≤10MB
function validateImageFile(file) {
  return new Promise((resolve, reject) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' || file.type === 'image/avif';
    if (!isJpgOrPng) return reject(new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件'))
    // if (file.size / 1024 / 1024 > 10) return reject(new Error('图片大小应该≤10MB'))  

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        const aspectRatio = width / height;
        // if (width < 500 || height < 500) return reject(new Error('图片尺寸不小于500*500'))
        if (aspectRatio > 2 || aspectRatio < 0.5) return reject(new Error('图片比例不超过2:1'))
        resolve(true)
      };
      img.onerror = function () {
        reject(new Error("无法读取图像文件"));
      };
      img.src = event.target.result;
    };
    reader.onerror = function () {
      reject(new Error("无法读取文件"));
    };
    reader.readAsDataURL(file);
  }).catch(err => {
    message.warning(err.message);
    return false
  })

}

// 判断设备是否为手机
function isMobileDevice() {
  return isMobile
}

// 上传base64图片
function uploadBase64Image(base64, isPng = true) {
  let formdata1 = new FormData
  formdata1.append('file', convertBase64ToFile(base64, isPng ? 'image.png' : 'image.jpg'))
  return API.uploadImage(formdata1).then(res => {
    if (res?.code != 0) throw new Error(res?.message)
    return res?.data?.filename
  }).catch(err => {
    console.error(err);
    message.warning(err.message);
    return false;
  })
}
// 上传file图片，不走审核
function uploadFile(file) {
  let formdata1 = new FormData
  formdata1.append('file', file)
  return API.upload(formdata1).then(res => {
    if (res?.code != 0) throw new Error(res?.message)
    return res?.data?.filename
  }).catch(err => {
    console.error(err);
    message.warning(err.message);
    return false;
  })
}
/*
    埋点
    imageType：可选model dressform commodity clothes
    opType  可选algorithm function resource

    其中算法类：

    智能补光 aiFillLight
    智能美化 aiBeauty
    魔法擦除 aiRemove
    画质升级 aiQualityUpgrade
    随心变形aiDraggan
    其中智能补光、智能美化为前端算法，画质升级和高清图同为SR算法，此三类建议埋点

    功能类：

    跳转 navigation
    选择图片类型 imageType
    换模特 changeModel
    换背景 changeBackgroud
    复刻 reprint
    生成更多 generateMore
    下载 download
    以上功能需埋点。attr可传一个json对象，例如下载，需传用户下载的具体图片
*/
function useroplog(imageType, opType, opName, attr) {
  API.useroplog({
    imageType: imageType ? imageType : IMAGE_TYPE.MODEL,
    opType: opType,
    opName: opName,
    attr: attr,
  }).catch(() => { })
}

// 检查上传图片是否符合安全规范
const checkImageIslegalWithoutToken = async (file, token, cm = 0, source) => {
  try {
    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('cm', cm);
    formdata.append('token', token);
    if (source) formdata.append('source', source);
    const res = await API.uploadImageWithToken(formdata);
    const result = {
      isLegal: true,
      imageAntispamId: "",
      data: ""
    }

    if (res.code === 0) {
      if (cm === 1) {
        result.imageAntispamId = res?.data?.imageAntispamId
        result.data = res.data.filename
      } else {
        result.data = res.data.filename
      }
    } else if (res.code === -5024) {
      result.isLegal = false
    } else {
      throw new Error(res.message);
    }

    return result
  } catch (err) {
    console.error(err);
    message.warning(err.message);
    return false;
  }
};

// 检查上传图片是否符合安全规范
const checkImageIslegal = async (file, cm = 0, source, figureCheck = false) => {
  try {
    let formdata = new FormData();
    formdata.append('file', file);
    formdata.append('cm', cm);
    if (source) formdata.append('source', source);
    formdata.append('figureCheck', figureCheck);
    const res = await API.uploadImage(formdata);
    const result = {
      isLegal: true,
      imageAntispamId: "",
      data: ""
    }

    if (res.code === 0) {
      if (cm === 1) {
        result.imageAntispamId = res?.data?.imageAntispamId
        result.data = res.data.filename
      } else {
        result.data = res.data.filename
      }
    } else if (res.code === -5024) {
      result.isLegal = false
    } else {
      throw new Error(res.message);
    }

    return result
  } catch (err) {
    console.error(err);
    message.warning(err.message);
    return false;
  }
};

// 打开本地文件夹
const openFile = (multiple = false) => {
  return new Promise((resolve, reject) => {
    let fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.multiple = multiple;
    fileInput.addEventListener('change', function () {
      if (multiple) {
        let files = fileInput.files;
        if (files.length > 0) {
          resolve(files)
        }
      } else {
        let file = fileInput.files[0];
        if (file) {
          resolve(file)
        }
      }
    });
    fileInput.click();
  })
};
const openFile2 = (multiple = false) => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = multiple;
    input.accept = 'image/*'
    let flag = 0
    const onForcus = () => {
      setTimeout(() => {
        if (flag == 0) {
          reject(new Error('cancel'))
        }
        window.removeEventListener('focus', onForcus)
      }, 500)
    }
    window.addEventListener('focus', onForcus)
    input.addEventListener('change', () => {
      flag = 1
      if (multiple) {
        let files = input.files;
        if (files.length > 0) {
          const fileList = []
          for (let i = 0; i < files.length; i++) {
            const file = files[i]
            if (!file.uid) {
              const url = URL.createObjectURL(file)
              file.uid = url.split('/').pop()
            }
            fileList.push(file)
          }
          resolve(fileList)
          input.remove()
        } else {
          reject(new Error('cancel'))
        }
      } else {
        const file = input.files[0];
        if (file) {
          resolve(file)
          input.remove()
        } else {
          reject(new Error('cancel'))
        }
      }
    })
    input.click()
  })
}

function addCommasToNumber(input) {
  let str = '';

  if (typeof input === 'number') {
    str = input.toString();
  } else if (typeof input === 'string') {
    str = input;
  } else {
    return;
  }
  let integer = str.split('.')[0]
  let decimal = str.split('.')[1]

  str = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  if (decimal && decimal != '00') str += '.' + decimal

  return str;
}

// 将阿里云OSS图片转为webp格式显示
function getImgUrlWithWebp(url, resize = false, blur = false) {
  if (!url) {
    return url;
  }
  let suffix = 'x-oss-process=image'
  if (resize) suffix += '/resize,w_800/quality,q_90'
  if (blur) suffix += '/blur,r_4,s_4'
  suffix += '/format,webp'
  if (url.indexOf('?') >= 0) {
    url = url + '&' + suffix;
  } else {
    url = url + '?' + suffix;
  }
  return url;
}

// 将图片转为渐进显示模式
function getImgUrlWithInterlace(url) {
  if (!url) {
    return url;
  }
  let suffix = 'x-oss-process=image/format,jpg/interlace,1'
  if (url.indexOf('?') >= 0) {
    url = url + '&' + suffix;
  } else {
    url = url + '?' + suffix;
  }
  return url;
}

// 通过fabric加载url
function loadImageUrlByFabric(url) {
  return new Promise(resolve => {
    fabric.Image.fromURL(url, (img) => {
      resolve(img)
    }, { crossOrigin: 'anonymous' })
  })
}

// 制作偏移图像
const makeOffsetImgByFabric = (
  imgUrl,
  imgRealScale,
  offsetX, offsetY,
  clipWidth, clipHeight,
  bgColor = 'rgba(0,0,0,255)') => {
  const toFixed = (num, precision = 4) => {
    return Number(num.toFixed(precision))
  }
  return new Promise(async (resolve, reject) => {
    const img = await loadImageUrlByFabric(imgUrl)
    const tempCanvas = new fabric.StaticCanvas(null, {
      preserveObjectStacking: true,
      width: img.width,
      height: img.height,
      selection: false,
      backgroundColor: bgColor
    })

    // 这里需要先四舍五入，否则tempCanvas.toDataURL时会自动舍弃小数位
    clipWidth = Math.round(clipWidth)
    clipHeight = Math.round(clipHeight)

    const scaleX = clipWidth / img.width
    const scaleY = clipHeight / img.height
    const minScale = Math.min(scaleX, scaleY)

    const imgScale = toFixed(minScale * imgRealScale)

    img.set({
      left: offsetX * scaleX,
      top: offsetY * scaleY,
    }).scale(imgScale)

    tempCanvas.add(img)
    tempCanvas.renderAll()
    const base64 = tempCanvas.toDataURL({
      format: 'png',
      width: clipWidth,
      height: clipHeight
    })
    resolve(base64)
  })
}

// 将透明底的mask,有颜色部分改为指定颜色
function modifyTransparentMask(base64Str, color) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      // 遍历图像数据,将非透明部分改为指定颜色
      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] > 0) {
          data[i] = parseInt(color.slice(1, 3), 16);
          data[i + 1] = parseInt(color.slice(3, 5), 16);
          data[i + 2] = parseInt(color.slice(5, 7), 16);
        }
      }
      ctx.putImageData(imageData, 0, 0);

      const newBase64 = canvas.toDataURL('image/png');
      resolve(newBase64);
    };
    img.onerror = reject
    img.src = base64Str;
  })
}

async function mergeBase64Images(base64Image1, base64Image2) {
  // 将 Base64 字符串转换为 Uint8Array
  const data1 = atob(base64Image1.split(',')[1]);
  const data2 = atob(base64Image2.split(',')[1]);
  const uint8Array1 = new Uint8Array(data1.length);
  const uint8Array2 = new Uint8Array(data2.length);
  for (let i = 0; i < data1.length; i++) {
    uint8Array1[i] = data1.charCodeAt(i);
  }
  for (let i = 0; i < data2.length; i++) {
    uint8Array2[i] = data2.charCodeAt(i);
  }

  // 创建一个新的 Canvas 元素
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // 加载第一张图片
  const img1 = new Image();
  img1.src = base64Image1;
  await new Promise((resolve) => {
    img1.onload = resolve;
  });
  canvas.width = img1.width;
  canvas.height = img1.height;
  ctx.drawImage(img1, 0, 0);

  // 加载第二张图片并将其绘制到 Canvas 上
  const img2 = new Image();
  img2.src = base64Image2;
  await new Promise((resolve) => {
    img2.onload = resolve;
  });
  ctx.drawImage(img2, 0, 0);

  // 将 Canvas 转换为 Base64 字符串
  return canvas.toDataURL('image/png');
}

// 判断域名是否包含"tiktok"字段
function getCurrentDomainAndCheckTiktok() {
  return /tiktok/.test(window?.location?.hostname) // || (API.url == 'http://172.17.13.140:8083');
}
function getCurrentDomainAndCheckShein() {
  return /shein/.test(window?.location?.hostname)
}

// 恢复task数据
function cacheTaskInfo(taskId, projectName, imageType) {
  return API.getTaskInfo({ taskId }).then(res => {
    if (res.code != 0) throw new Error(res.message)
    const input = res?.data?.inpaint?.input
    const attr = res?.data?.inpaint?.attr
    const mask = res?.data?.mask
    const commodityFilter = attr?.commodityFilter
    const modelFilter = attr?.modelFilter
    let clothType = CLOTH_TYPE.SKIRT
    if (imageType == IMAGE_TYPE.CLOTHES) {
      if (!mask?.algoId) clothType = CLOTH_TYPE.SUIT
    }

    // 原始输入图
    let inputImage, inputImageTop, inputImageBottom
    const topAlgoId = mask?.topAlgoId
    const bottomAlgoId = mask?.bottomAlgoId
    const _inputImage = res?.data?.inputImage || ""
    const inputImageArray = _inputImage ? _inputImage.split(',') : []
    if (topAlgoId && bottomAlgoId) {
      inputImageTop = inputImageArray[0] || mask?.topEditedOutput[0]
      inputImageBottom = inputImageArray[1] || mask?.bottomEditedOutput[0]
    } else if (topAlgoId) {
      inputImageTop = inputImageArray[0] || mask?.topEditedOutput[0]
    } else if (bottomAlgoId) {
      inputImageBottom = inputImageArray[0] || mask?.bottomEditedOutput[0]
    } else {
      inputImage = inputImageArray[0] || mask?.editedOutput[0]
    }

    // 处理imgRatio，兼容模特生成的旧数据
    let imgRatio = attr?.imgRatio || '1:1'
    if (imageType == IMAGE_TYPE.MODEL) {
      if (!input?.hasOwnProperty('coord_drag')) imgRatio = 'custom'
      if (imgRatio == 'recommend') imgRatio = 'custom' // 原来的推荐删除，改为了自定义
      if (imgRatio == 'original') imgRatio = 'custom' // 原来的原始比例删除，改为了自定义
    }

    // 处理一些前端交互参数
    // definedFlag为1是前端tab切换到自定义场景
    let definedFlag = input?.defined_flag
    const isSelfDefined = input?.self_defined == 1 ? true : false
    const selfDefinedImg = input?.mote_img

    let isCustomScene = input.keep_bkg == 3 || definedFlag == 1 // 场景裂变时definedFlag=0，但属于自定义场景
    let refStrength = 0.5
    let refVersion = 2
    let isSceneFracturing = input.keep_bkg == 3 // 场景裂变
    let dpScale = 0.5
    let isSameStyle = input.same_pattern == 1 // 拍同款
    let sameStyleScale = 0.5
    let isSimilarStyle = isCustomScene && !isSceneFracturing && !isSameStyle // 相似风格
    let similarStyleScale = 0.5
    const positivePrompt = input?.p_prompt || input?.prompt
    const refImg = input?.ref_img || input?.bkg_url
    const refImgMask = input?.ref_img_mask || input?.bkg_url_mask
    const refImgErased = input?.bkg_url_erased
    if (imageType == IMAGE_TYPE.MODEL) {
      if (isSceneFracturing) {
        definedFlag = 1 // 自定义场景，definedFlag取1
        refVersion = 2 // 场景裂变，refVersion取2
        const dp_scale = input?.hasOwnProperty('dp_scale') ? input?.dp_scale : 0.2
        refStrength = Number(dp_scale) // 场景裂变，强度取dp_scale
        dpScale = refStrength
      } else if (isSameStyle) {
        definedFlag = 1 // 自定义场景
        refVersion = 3 // 拍同款，refVersion取3
        const ip_scale = input?.hasOwnProperty('ip_scale') ? input?.ip_scale : 0.5
        refStrength = Number(ip_scale) // 拍同款，强度取ip_scale
        sameStyleScale = refStrength
      } else if (isSimilarStyle) {
        definedFlag = 1 // 自定义场景
        refVersion = 1 // 相似风格，refVersion取1
        const ip_scale = input?.hasOwnProperty('ip_scale') ? input?.ip_scale : 0.5
        refStrength = Number(ip_scale) // 相似风格，强度取ip_scale
        similarStyleScale = refStrength
      }
    } else if (imageType == IMAGE_TYPE.COMMODITY) {
      // 设置refVersion
      refVersion = input?.refine_version || 2
      // 设置refStrength
      const ref_strength = input?.ref_strength || 0
      const ref_prompt_strength = input?.ref_prompt_strength || 0
      if (refVersion == 1 || refVersion == 3) { // 背景合成/拍同款，强度取ref_strength
        refStrength = ref_strength
      } else { // 相似风格，强度取ref_prompt_strength
        refStrength = ref_prompt_strength
      }
    }

    sessionStorage.setItem(`taskCache_${imageType}`, JSON.stringify({
      inputImage: inputImage,
      inputImageTop: inputImageTop,
      inputImageBottom: inputImageBottom,

      commodityFilter: commodityFilter,
      modelFilter: modelFilter,
      dragScale: input?.imgs_dict,

      lt_x: input?.lt_x || 0,
      lt_y: input?.lt_y || 0,
      scaleRate: input?.scale_rate || 1,

      coordDrag: input?.coord_drag ? JSON.parse(input?.coord_drag) : null,

      maskAlgoId: mask?.algoId,
      maskAlgoId1: mask?.topAlgoId,
      maskAlgoId2: mask?.bottomAlgoId,

      segmentResult: mask?.editedOutput,
      segmentResult1: mask?.topEditedOutput,
      segmentResult2: mask?.bottomEditedOutput,

      modelStyle: attr?.person,
      poseStyleList: (attr?.pose && !isSelfDefined) ? [{ name: attr?.pose, isSelfDefined, selfDefinedImg }] : [],
      customPoseStyleList: (attr?.pose && isSelfDefined) ? [{ name: attr?.pose, isSelfDefined, selfDefinedImg }] : [],
      bgStyle: attr?.background,

      projectId: res.data.projectId,
      rightProjectId: res.data.projectId,
      projectName: projectName,
      clothType: clothType, //+++++++++
      createNum: input?.num || 4,

      imgRatio: imgRatio,
      imgWidth: input?.canvas_width || 1024,
      imgHeight: input?.canvas_height || 1024,

      definedFlag: definedFlag,
      positivePrompt: positivePrompt,
      refImg: refImg,
      refImgMask: refImgMask,
      refImgErased: refImgErased,
      refVersion: refVersion,
      refStrength: refStrength,
      faceTagText: attr?.faceTagText || '默认',
      hairTagText: attr?.hairTagText || '默认',

      isCustomScene: isCustomScene,

      isSameStyle,
      sameStyleScale,

      isSimilarStyle,
      similarStyleScale,

      isSceneFracturing,
      dpScale,

      keepPose: input.keepPose == 1 ? true : false,
      keepBkg: input.keepBkg == 1 ? true : false,
    }))
  }).catch(err => {
    console.error(err)
    message.error(err.message)
  })
}

async function getFilesFromDirs(filesAndDirs, path) {
  let okFiles = [];   //递归文件夹后 保存所有file的Array
  let step = 0;       //当前遍历次数
  let folderNum = 1;  //需要遍历的次数  有一个子文件夹就需要遍历一次
  const iterateFilesAndDirs = async function (filesAndDirs, path) {  // 遍历目录的文件夹和文件
    const folderArr = filesAndDirs.filter((item) => {  //当前目录的子文件夹
      return item._items && item._items.isDirectory;
    });

    folderNum += folderArr.length;  // 当前目录下还需遍历多少次 有多少个子文件夹就需要遍历几次
    step += 1;  //遍历次数
    for (let i = 0; i < filesAndDirs.length; i++) {
      if (typeof filesAndDirs[i].getFilesAndDirectories === "function") { //文件夹 遍历
        let dpath = filesAndDirs[i].path;
        // 递归遍历各级目录
        const subFilesAndDirs = await filesAndDirs[i].getFilesAndDirectories()
        // 遍历子目录的文件夹和文件
        return await iterateFilesAndDirs(subFilesAndDirs, dpath);
      } else {
        //如为文件则存储在okFiles 里面
        let file = filesAndDirs[i];
        file.fullPath = path; // 组装该文件的相对路径
        if (!file.uid) {
          const url = URL.createObjectURL(file)
          file.uid = url.split('/').pop()
        }
        okFiles.push(file);
      }
    }
    if (step >= folderNum) { // 计算是否遍历完毕  即 当前共遍历了多少次 == 总共需要遍历的次数（文件夹数量） 则遍历完毕
      // 确定是最后一次遍历后执行上传操作
      // console.log(okFiles); //得到此次上传的所有文件 不包含空文件夹
      return okFiles
    }
  };
  return await iterateFilesAndDirs(filesAndDirs, path)
}

// 获取模特mask
const getFrontImage = (origin, mask, blueMask) => {
  // 将mask的白色部分转为透明色
  const changeWhiteToTransparent = (url) => {
    return new Promise(resolve => {
      const image = new Image();
      image.crossOrigin = "anonymous"
      image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context.drawImage(image, 0, 0);
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          // 将原颜色替换为指定颜色
          if (data[i] > 128) {
            data[i + 3] = 0
          }
        }
        context.putImageData(imageData, 0, 0);
        const newBase64 = canvas.toDataURL();
        resolve(newBase64);
      };
      image.src = url;
    });
  }
  return new Promise(async (resolve, reject) => {
    // console.log('segmentResult[0]', segmentResult[0])
    const segmentResult3 = await changeWhiteToTransparent(mask)
    const img0 = await loadImageUrlByFabric(origin)
    const img3 = await loadImageUrlByFabric(segmentResult3)

    const tempCanvas = new fabric.StaticCanvas(null, {
      preserveObjectStacking: true,
      width: img0.width,
      height: img0.height,
      selection: false
    })
    img3.set({ selectable: false, globalCompositeOperation: 'destination-out' })
    tempCanvas.add(img3)
    if (blueMask) {
      const img1 = await loadImageUrlByFabric(blueMask)
      img1.set({ selectable: false })
      tempCanvas.add(img1)
    }
    tempCanvas.setBackgroundImage(img0, () => {
      tempCanvas.renderAll()
      const base64 = tempCanvas.toDataURL({ format: 'png' })
      resolve(base64)
    })
  })
}

export default {
  getForegroundImage: getForegroundImage,
  urlToBase64: urlToBase64,
  urlToFile,
  resizeImage: resizeImage,
  convertBase64Image: convertBase64Image,
  convertBase64ToFile: convertBase64ToFile,
  getTrimmedImageBase64: getTrimmedImageBase64,
  getTrimmedSizeByBlackMask,
  getTrimmedImageBySize,
  mergeBodyAndClothMask,
  replaceColor: replaceColor,
  chunkArray: chunkArray,
  downloadFileWithToken: downloadFileWithToken,
  validateContact: validateContact,
  validateContact2,
  downUrl: downUrl,
  downImageZip,
  delayFunc: delayFunc,
  getLongestSideLength: getLongestSideLength,
  cropImageBase64: cropImageBase64,
  getImageDimensions: getImageDimensions,
  getVideoDimensions,
  convertNonTransparentToColor,
  convertFileToBase64,
  convertWhiteImageToBlue,
  convertBlueImageToWhite,
  resizeImageFileMaxByAliyunBy,
  // resizedImageMaxOf,
  hdDownloadFunc,
  hdDownload4VideoFunc,
  getImgMaxSizeInContainer,
  resizeModelScale,
  convertImgColor,
  setOpaquePixels,
  addVersionParamToURL,
  parseErrorCode,
  hasAlphaChannel,
  isMobileDevice,
  uploadBase64Image,
  uploadFile,
  validateImageFile,
  useroplog,
  checkImageIslegal,
  checkImageIslegalWithoutToken,
  getHDParams, // 根据taskid获得高清生成参数
  toHDPreview, // 高清预览生成
  openFile,
  openFile2,
  addCommasToNumber,
  getImgUrlWithWebp,
  getImgUrlWithInterlace,
  videoTryonImageCheck,
  makeOffsetImgByFabric,
  loadImageUrlByFabric,
  reverseTransparent, // 反转透明的部分到指定颜色，其他颜色置为透明
  modifyTransparentMask,
  mergeBase64Images,
  getCurrentDomainAndCheckTiktok,
  getCurrentDomainAndCheckShein,
  cacheTaskInfo,
  getFilesFromDirs,
  getFrontImage,
  convertTransparentWhiteToOpaque,
  qrcodeCommunity: `https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/qrcode_community.png?t=${Math.floor(new Date().getTime() / 1000 / 60)}`,
  qrcodeSales: `https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/qrcode_sales.jpg?t=${Math.floor(new Date().getTime() / 1000 / 60)}`,
}