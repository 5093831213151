import styles from './MaskClothes.module.scss'
import { useEffect, useRef, useState, useContext, useCallback } from 'react'
import { Upload, Spin } from 'antd'
import Utils from '@utils/utils'
import { AppContext } from '@utils/AppContext';
import ToolBox from '../ToolBox/ToolBox';
import { maskDispose } from "@utils/workflowOfPrediction"
import useResizeObserver from "@utils/useResizeObserver"
import { GenerateContext } from "@utils/GenerateContext"

const MaskClothes = (props) => {
    const isMounted = useRef(true)
    const {setGlobalLoading} = useContext(AppContext);
    const [deskWidth, setDeskWidth] = useState(0)
    const [deskHeight, setDeskHeight] = useState(0)
    const [imgSize, setImgSize] = useState({ widt: 0, height: 0})
    const {
        setClothLoadingFlag, setClothLoadingFlag2
    } = useContext(GenerateContext)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (props.segmentResult.length == 0) return
        if (!props.aiInteractiveNpy) {
            maskDispose(props.segmentResult, () => !isMounted.current).then(res => {
                props.onNpyChange(res?.npy)
            })
        }
    }, [props.segmentResult, props.aiInteractiveNpy])

    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setDeskWidth(entry.contentRect.width)
            setDeskHeight(entry.contentRect.height)
        }
    })

    useEffect(() => {
        if (!(deskWidth * deskHeight)) return
        // setGlobalLoading('加载中')
        const maskImg = props.segmentResult[2]
        Utils.getImageDimensions(maskImg).then(({width, height}) => {
            const size = Utils.getImgMaxSizeInContainer(
                width,
                height,
                deskWidth,
                deskHeight
            )
            setImgSize({
                width: size.width,
                height: size.height
            })
        // }).finally(() => {
        //     setGlobalLoading(false)
        })
    }, [deskWidth, deskHeight, props.segmentResult])

    const [loading, setLoading] = useState(false)
    const reUpload = async (...args) => {
        setLoading(true)
        try {
            if(props.bodyPart == 1) {setClothLoadingFlag(true)}
            if(props.bodyPart == 2) {setClothLoadingFlag2(true)}
            return await props.reUpload(...args)
        } finally {
            setLoading(false)
        }
    }

    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        console.log('===uploadDebounce===')
        setLoading(true)
        try {
            if(props.bodyPart == 1) {setClothLoadingFlag(true)}
            if(props.bodyPart == 2) {setClothLoadingFlag2(true)}
            return await props.reUpload(file)
        } finally {
            setLoading(false)
        }
    }, 100), [])

    const handleDrop = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            if (filesAndDirs.length == 0) return false
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }

    return (
        <Upload.Dragger
            accept={'image/jpeg,image/png,image/webp,image/avif'}
            name="avatar"
            showUploadList={false}
            className={styles.UploadWrap}
            maxCount={1}
            beforeUpload={()=>false}
            onDrop={handleDrop}
        >
            <div style={{width: "100%",height:"100%",cursor:"default"}} onClick={e => e.stopPropagation()}>
                <div className={styles.Container}>
                    <div className={styles.MaskDeskWrap} ref={targetRef}>
                    {
                        (imgSize.width * imgSize.height) ?
                        <div className={styles.MaskDesk} style={{ width: imgSize.width, height: imgSize.height ,position: 'relative'}}>
                            <img className={styles.MaskMap} src={props.segmentResult[2]} />
                            <img onClick={props.deleteOpt} src={require('@assets/images/incorrect (1).png')}/>
                        </div> :
                        ""
                    }
                    </div>
                    <div className={styles.ToolBox}>
                        <ToolBox
                            composeDisabled
                            border={props.border}
                            reUpload={reUpload}
                            mattingOpt={props.mattingOpt}
                            composeOpt={props.composeOpt}
                            deleteable={true}
                        />
                    </div>
                    {
                        loading &&
                        <div className={styles.Loading}>
                            <Spin size="large" />
                        </div>
                    }
                </div>
            </div>
        </Upload.Dragger>
    )
}

export default MaskClothes