import { useContext, useCallback } from "react"
import MaskDressform from "../components/MaskDressform/MaskDressform"
import MaskModel from "../components/MaskModelWithBgMoveable"
// import MaskModel from "../components/MaskModel/MaskModel"
import UploadModelAndCommodity from "../components/UploadModelAndCommodity"
import { IMAGE_TYPE } from '@utils/CONST'
import { GenerateContext } from "@utils/GenerateContext"
import { Upload } from "antd"
import styles from "./index.module.scss"
import Utils from "@utils/utils"

const SubCompModel = (props) => {
    const {
        imgRatio,
        inputImage,
        maskMoveable,
        setGlobaluploadLoading
    } = useContext(GenerateContext)

    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        console.log('===uploadDebounce===')
        setGlobaluploadLoading(true)
        await props.reUpload(file)
    }, 100), [])

    const handleDrop = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            if (filesAndDirs.length == 0) return false
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }

    return (
        props.segmentResult?.length > 0 ?
        <Upload.Dragger
            accept={'image/jpeg,image/png,image/webp,image/avif'}
            name="avatar"
            showUploadList={false}
            className={styles.UploadWrap}
            maxCount={1}
            beforeUpload={()=>false}
            onDrop={handleDrop}
        >
            <div style={{width: "100%",height:"100%",cursor:"default"}} onClick={e => e.stopPropagation()}>
            {
                // 保持原图场景/场景裂变，人物不可移动
                maskMoveable ?
                <MaskModel
                    key={props.segmentResult[0]}
                    imgRatio={imgRatio}
                    imageType={props.imageType}
                    aiInteractiveNpy={props.aiInteractiveNpy}
                    inputImage={inputImage}
                    segmentResult={props.segmentResult}
                    reUpload={props.reUpload}
                    deleteOpt={props.deleteOpt}
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onNpyChange={props.onNpyChange}
                /> :
                <MaskDressform
                    key={props.segmentResult[0]}
                    imageType={props.imageType}
                    aiInteractiveNpy={props.aiInteractiveNpy}
                    segmentResult={props.segmentResult}
                    reUpload={props.reUpload}
                    // deleteOpt={props.deleteOpt} // 不需要关闭按钮
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onNpyChange={props.onNpyChange}
                />
            }
            </div>
        </Upload.Dragger>
        :
        <UploadModelAndCommodity
            imageType={props.imageType}
            onBeforeUpload={props.onBeforeUpload}
        />
    )
}

export default SubCompModel