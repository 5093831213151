// AppContext.js
import { Alert, Button, message } from 'antd';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as _ from "underscore";
import Utils from './utils'
import API from '@api/api'
import { HOME_PAGE_TYPE, IMAGE_TYPE } from './CONST';
import { useLocation } from 'react-router-dom';
import eventBus from './eventBus';
import dayjs from 'dayjs';

export const AppContext = createContext();

const initialConfig = {
  sidebarKey: 3, // 确定侧边栏菜单选中状态
  loginHeadIndex: 1, // 首页顶部菜单切换
};

// ===========用户虹豆提醒 BEGIN=================
const showNoticeAlert = (key, keyStr, alertMessage) => {
    message.open({
        className: 'custom-alert-message',
        content: (
            <Alert
                message={alertMessage}
                type="warning"
                showIcon
                closable
                action={ <Button type='link' style={{marginLeft: '16px'}} size='small' onClick={() => notShowAgain(key, keyStr)}>不再提示</Button> }
                afterClose={() => destroyNoticeAlert(key)}
            />
        ),
        duration: 0,
        key: key,
    })
}
const destroyNoticeAlert = (key) => {
    message.destroy(key)
}
const notShowAgain = (key, keyStr) => {
    localStorage.setItem(keyStr, 1)
    destroyNoticeAlert(key)
}
// 清除已过期的提醒
function clearExpiredNotShowAgainFlag () {
    const deleteKeys = []
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const res = /^not_show_again_power_expire_soon_(.+)_(\d+)/.exec(key)
        if (res) {
            const expiredTime = dayjs(Number(res[2]))
            const today = dayjs(new Date()).format('YYYY-MM-DD')
            const diff = expiredTime.diff(today, 'day')
            if (diff < 0) deleteKeys.push(key)
        }
        const res2 = /^not_show_again_plan_expire_soon_(.+)_(\d+)/.exec(key)
        if (res2) {
            const expiredTime = dayjs(Number(res2[2]))
            const today = dayjs(new Date()).format('YYYY-MM-DD')
            const diff = expiredTime.diff(today, 'day')
            if (diff < 0) deleteKeys.push(key)
        }
    }
    for (let key of deleteKeys) localStorage.removeItem(key)
}
// 虹豆即将过期提醒
const checkPowerIsExpireSoon = (list) => {
    const today = dayjs(new Date()).format('YYYY-MM-DD')
    for (let item of list) {
        const expiredTime = dayjs(item.expiredTime)
        const diff = expiredTime.diff(today, 'day')
        if (diff <= 7 && diff >= 0) {
            const key = `${item.membershipId}_${expiredTime.valueOf()}`
            const keyStr = 'not_show_again_power_expire_soon_'+key
            const notShowAgain_powerExpiredTime = localStorage.getItem(keyStr)
            if (notShowAgain_powerExpiredTime != '1') {
                const daysMessage = diff > 0 ?
                    `将在${diff}天后过期` : 
                    `即将过期`
                const alertMessage = [2,3].includes(item.planType) ?
                    `温馨提示：您的${item.subject}还有${item.powerBalance}点虹豆，${daysMessage}，请尽快使用哦~` :
                    `温馨提示：您购买的${item.subject}还有${item.powerBalance}点虹豆，${daysMessage}，请尽快使用哦~`
                showNoticeAlert(key, keyStr, alertMessage)
            }
        }
    }
}
// 套餐即将过期提醒
const checkPackageIsExpireSoon = (list) => {
    const today = dayjs(new Date()).format('YYYY-MM-DD')
    for (let item of list) {
        const expiredTime = dayjs(item.expiredTime)
        const diff = expiredTime.diff(today, 'day')
        if (diff <= 7 && diff >= 0) {
            const key = `${item.membershipId}_${expiredTime.valueOf()}`
            const keyStr = 'not_show_again_plan_expire_soon_'+key
            const notShowAgain_powerExpiredTime = localStorage.getItem(keyStr)
            if (notShowAgain_powerExpiredTime != '1') {
                const daysMessage = diff > 0 ?
                    `将在${diff}天后结束` : 
                    `即将结束`
                const alertMessage = `温馨提示：您购买的${item.subject}套餐当前服务周期${daysMessage}，请尽快续费以继续享受会员权益~`
                showNoticeAlert(key, keyStr, alertMessage)
            }
        }
    }
}

export const AppContextProvider = ({ children }) => {
    const [isProEnv] = useState(API.url == 'https://photostudio.arcsoft.com.cn'  || API.url == 'http://canary.ps.arcsoftbiz.com')
    const location = useLocation()
    const [globalHomePageType, setGlobalHomePageType] = useState() // 服装版/商品版
    
    const [globalActivity, setGlobalActivity] = useState() // 运营活动
    const [globalBalance, setGlobalBalance] = useState(0) // 可用虹豆
    const [config, setConfig] = useState(initialConfig);
    const [globalLoading, setGlobalLoading] = useState(false)
    const [hasToken, setHasToken] = useState(localStorage.getItem('token')) // 存在token
    const [globalUser, setGlobalUser] = useState() // 用户信息
    const [globalMemberList, setGlobalMemberList] = useState([]) // 会员套餐记录
    const [globalOrderList, setGlobalOrderList] = useState([]) // 订单记录，用于即将过期提醒
    const [globalCommodityFilter, setGlobalCommodityFilter] = useState({}) // commodity filter
    const [globalModelFilter, setGlobalModelFilter] = useState({}) // model filter
    const filteredModelListRef = useRef([])
    const filteredBgListRef = useRef([])
    // const globalProInfoRef = useRef(initProInfo)
    const [messageApi, contextHolder] = message.useMessage();

    // 大客户专属定制功能
    const hasPrivateAbility = useCallback((value) => {
        if (!globalUser) return false
        const privateAbilities = globalUser.privateAbilities || []
        return privateAbilities.includes(value)
    }, [globalUser])

    useEffect(() => {
        const homePageType = sessionStorage.getItem('HomePageTypeSelect')
        if (!homePageType) {
            setGlobalHomePageType(HOME_PAGE_TYPE.CLOTHING)
            sessionStorage.setItem('HomePageTypeSelect', HOME_PAGE_TYPE.CLOTHING)
        } else {
            setGlobalHomePageType(homePageType)
        }
    }, [])
    useEffect(() => {
        const pathname = location.pathname
        if (pathname == '/home/homemain/model') updateGlobalHomePageType(HOME_PAGE_TYPE.CLOTHING)
        else if (pathname == '/home/homemain/dressform') updateGlobalHomePageType(HOME_PAGE_TYPE.CLOTHING)
        else if (pathname == '/home/homemain/clothes') updateGlobalHomePageType(HOME_PAGE_TYPE.CLOTHING)
        else if (pathname == '/home/homemain/model_video') updateGlobalHomePageType(HOME_PAGE_TYPE.CLOTHING)
        else if (pathname == '/home/homemain/commodity') updateGlobalHomePageType(HOME_PAGE_TYPE.MERCHANDISE)
        else if (pathname == '/home/homemain/image_service') updateGlobalHomePageType(HOME_PAGE_TYPE.IMAGE_SERVICE)
    }, [location.pathname])
    
    const updateGlobalHomePageType = (value) => {
        setGlobalHomePageType(value)
        sessionStorage.setItem('HomePageTypeSelect', value)
    }

    const updateConfig = (param) => {
      // 如果依赖config，请使用回调修改config，以免造成数据不同步
      if (typeof param === 'function') {
        setConfig(preConfig => {
          return { ...preConfig, ...param(preConfig) }
        })
      } else {
        setConfig(preConfig => {
          return { ...preConfig, ...param }
        })
      }
    };

    useEffect(() => {
        clearExpiredNotShowAgainFlag()
        checkPowerIsExpireSoon(globalMemberList.filter(item => item.status == '0' && !!item.expiredTime && item.planType != 0 && item.powerBalance > 0)) // 1-加油包; 2|3-赠点
        checkPackageIsExpireSoon(globalMemberList.filter(item => item.status == '0' && !!item.expiredTime && item.planType == 0)) // 0-套餐
    }, [globalMemberList])

    const [update ,setUpdate] = useState() // 触发更新flag
    // const forceUpdate = _.throttle((value) => {
    //   console.log('throttle exec setUpdate', value, Math.floor(new Date().getTime() / 1000))
    //   setUpdate(value)
    // }, 500)

    // const forceUpdate = _.debounce((value) => {
    //   console.log('debounce exec setUpdate', value, Math.floor(new Date().getTime() / 1000))
    //   setUpdate(value)
    // }, 500)
    const forceUpdate = (value) => {
        // console.log('exec setUpdate', value, Math.floor(new Date().getTime() / 1000))
        setUpdate(value)
    }

    // 全局超分下载
    const hdDownloadWithLoadingFunc = async (taskId, imageId, imageType, topic) => {
        const key = 'updatable'+imageId
        messageApi.open({
            key,
            type: 'loading',
            content: '下载中...',
            duration: 0,
        })
        try {
            const params = await Utils.getHDParams(taskId, imageType, topic)
            console.log('params', params)
            let algoId, res
            if (imageType == IMAGE_TYPE.COMMODITY_VIDEO || imageType == IMAGE_TYPE.MODEL_VIDEO) {
                res = await Utils.hdDownload4VideoFunc({
                    videoPath: params.img_path,
                    scale: 2
                })
            } else {
                res = await Utils.hdDownloadFunc({
                    imgPath: params.img_path,
                    imgSrc: params.img_src,
                    maskCloth: params.mask_cloth,
                    moteImg: params.mote_img,
                    colorMask: params.color_mask,
                    imageH: params.imageH,
                    bodyMask: params.body_mask,
                    scale: 2
                })
            }
            algoId = res.algoId      
            res = await API.setHDImage({
                taskId,
                imageId,
                hdAlgoId: algoId
            })
            if (res?.code != 0) throw new Error(res?.message)
            res = await API.hdDownload({taskId})
            if (res?.code != 0) throw new Error(res?.message)
            const image = res.data
            Utils.downUrl(image, imageType)
            messageApi.open({
                key,
                type: 'success',
                content: '下载成功',
                duration: 2,
            })
            eventBus.emit('updatePower')
        } catch (err) {
            console.error(err)
            messageApi.open({
                key,
                type: 'warning',
                content: '下载失败，请稍后重试',
                duration: 2,
            })
        }
    }

    // 全局ai编辑加水印下载
    const aiEditDownloadWithLoadingFunc = async (imgPath, sourceAlgo, imageType, needPay) => {
        const key = 'updatable'+new Date().getTime()
        messageApi.open({
            key,
            type: 'loading',
            content: '下载中...',
            duration: 0,
        })
        try {
            const {algoId, output} = await Utils.hdDownloadFunc({imgPath, scale: 1})
            let res = await API.aiEditDownload({algoId, sourceAlgo, needPay})
            if (res?.code != 0) throw new Error(res?.message)
            Utils.downUrl(res.data, imageType, false)
            messageApi.open({
                key,
                type: 'success',
                content: '下载成功',
                duration: 2,
            })
            eventBus.emit('updatePower')
            return res.data
        } catch (err) {
            console.error(err)
            messageApi.open({
                key,
                type: 'warning',
                content: '下载失败，请稍后重试',
                duration: 2,
            })
        }
    }
    function generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = (d + Math.random()*16)%16 | 0;
          d = Math.floor(d/16);
          return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
        return uuid;
    }
    // 智能抠图批量下载扣点
    const batchMattingDownloadWithLoadingFunc = async (items, sourceAlgo) => {
        const key = 'updatable'+new Date().getTime()
        messageApi.open({
            key,
            type: 'loading',
            content: '下载中...',
            duration: 0,
        })
        try {
            const res = await Promise.allSettled(items.map(item => {
                const {uid, url, payed} = item
                const uuid = generateUUID()
                return API.aiEditDownload({algoId: uuid, sourceAlgo, needPay: !payed}).then(res => {
                    if (res?.code != 0) throw new Error(res?.message)
                    else return {
                        url,
                        uid
                    }
                })
            }))
            console.log('-----------', res)
            const successList = res
                .filter(item => item.status == "fulfilled")
                .map(item => item.value)
            if (successList.length == 0) {
                throw new Error('download is empty')
            } if (successList.length == 1) {
                successList.forEach(item => {
                    Utils.downUrl(item.url, IMAGE_TYPE.MODEL, false)
                })
            } else {
                await Utils.downImageZip(successList.map(item => item.url))
            }
            messageApi.open({
                key,
                type: 'success',
                content: '下载成功',
                duration: 2,
            })
            eventBus.emit('updatePower')
            return successList
        } catch (err) {
            console.error(err)
            messageApi.open({
                key,
                type: 'warning',
                content: '下载失败，请稍后重试',
                duration: 2,
            })
        }
    }
    // 智能抠图下载扣点
    const mattingDownloadWithLoadingFunc = async (imgPath, sourceAlgo, imageType, needPay) => {
        const key = 'updatable'+new Date().getTime()
        messageApi.open({
            key,
            type: 'loading',
            content: '下载中...',
            duration: 0,
        })
        try {
            // const {algoId, output} = await Utils.hdDownloadFunc({imgPath, scale: 1})
            const uuid = generateUUID()
            let res = await API.aiEditDownload({algoId: uuid, sourceAlgo, needPay})
            if (res?.code != 0) throw new Error(res?.message)
            Utils.downUrl(imgPath, imageType, false)
            messageApi.open({
                key,
                type: 'success',
                content: '下载成功',
                duration: 2,
            })
            eventBus.emit('updatePower')
            return true
        } catch (err) {
            console.error(err)
            messageApi.open({
                key,
                type: 'warning',
                content: '下载失败，请稍后重试',
                duration: 2,
            })
        }
    }

    return (
        <AppContext.Provider value={{
            isProEnv,
            globalActivity, setGlobalActivity,
            globalBalance, setGlobalBalance,
            globalHomePageType, updateGlobalHomePageType,
            config, updateConfig,
            update, forceUpdate,
            globalLoading, setGlobalLoading,
            hasToken, setHasToken,
            globalUser, setGlobalUser,
            globalMemberList, setGlobalMemberList,
            globalOrderList, setGlobalOrderList,
            globalCommodityFilter, setGlobalCommodityFilter,
            globalModelFilter, setGlobalModelFilter,
            filteredModelListRef,
            filteredBgListRef,
            hasPrivateAbility,
            hdDownloadWithLoadingFunc,
            aiEditDownloadWithLoadingFunc,
            mattingDownloadWithLoadingFunc,
            batchMattingDownloadWithLoadingFunc,
        }}>
            {contextHolder}
            {children}
        </AppContext.Provider>
    );
};

export const colorPrimary = '#393EFD'
export const colorCenterLine = '#00A0FF'
export const channel = localStorage.getItem('channel')