import { Button, Empty, Select, Spin, Upload, message} from 'antd'
import styles from './index.module.scss'
import API from '@api/api.js';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { GenerateContext } from '@utils/GenerateContext'
import eventBus from '@utils/eventBus';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import PSAIModal from '@PSAIComponents/PSAIModal';
import exampleJSON from '@assets/custom_tryonvideo_example/example.json'
import Utils from '@utils/utils'
import useIntersectionObserver from '@utils/useIntersectionObserver';

const VideoItem = (props) => {
    const {
        modelVideo,
    } = useContext(GenerateContext)

    const videoRef = useIntersectionObserver(async visible => {
        if (videoRef.current) {
            try {
                if (visible) await videoRef.current.play()
                else await videoRef.current.pause()
            } catch (err) {
                console.error(err)
            }
        }
    })

    return (
        <div
            className={`${styles.sceneItem} ${modelVideo?.name == props.record.name ? styles.video_select : null}`}
            onClick={() => props.onSelect(props.record)}
        >
        {
            /.webp$/.test(props.record.imageUrl) ?
            <img className={styles.sceneItemImg} src={props.record.imageUrl} /> : 
            <video
                ref={videoRef}
                className={styles.sceneItemImg}
                src={props.record.imageUrl}
                loop
                muted
                autoPlay
                disablePictureInPicture
                type="video/mp4"
            ></video>
        }
        {
            props.record.attr.private === 1 &&
            <i className={styles.private_icon}></i>
        }
        {
            (props.record.attr.skin == 'custom' || !!props.record.attr.custom) &&
            <i className={styles.del_btn} onClick={() => props.onRemove(props.record)}></i>
        }
        </div>
    )
}

export default function VideoSelection (props) {
    const {
        setModelVideo,
    } = useContext(GenerateContext)
    const [loading, setLoading] = useState(true)
    const [update, forceUpdate] = useState()
    // 筛选条件 皮肤/性别/年龄
    const [sex, setSex] = useState([])

    const [consumAmount] = useState(80)
    const [videoList, setVideoList] = useState([])
    const [customVideoList, setCustomVideoList] = useState([])

    useEffect(() => {
        setLoading(true)
        API.modelvideoList({
            source: props.isCustomVideo ? 1 : 0,
            sex: sex.join()
        }).then(res => {
            if (res.code != 0) throw new Error(res.message)
            const list = res.data
            if (props.isCustomVideo) {
                setCustomVideoList([...list])
            } else {
                setVideoList([...list])
            }
            setModelVideo({
                name: list[0]?.name,
                isCustom: list[0]?.attr.custom,
            })
        }).catch(err => {
            console.error(err)
            message.warning(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }, [sex, update])

    const selectVideo = (item) => {
        eventBus.emit('TOUR_NEXT')
        setModelVideo({
            name: item?.name,
            isCustom: item?.attr.custom,
        })
    }
    const optFactory = (opt, placeholder) => {
        return {
            mode: "multiple",
            allowClear: true,
            showSearch: false,
            style: {width: '120px'},
            size: 'small',
            maxTagCount: 'responsive',
            placeholder: placeholder,
            options: opt
        }
    }
    const sexOpts = [{
        value: 'female',
        label: '女',
    }, {
        value: 'male',
        label: '男',
    }]
    const tagRender = (props) => {
        const { label } = props;
        return <span className={styles.SelectionTag}>{label}</span>
    }

    const showList = useMemo(() => {
        if (props.isCustomVideo) return customVideoList
        else return videoList
    }, [videoList, customVideoList, props.isCustomVideo])

    // 图片要求
    const [open, setOpen] = useState()
    const onImgRequire = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const [uploadLoading, setUploadLoading] = useState(false)
    
   // measureFPS 方法：测量视频的帧率
function measureFPS(video) {
    return new Promise((resolve) => {
        let frameCount = 0;
        const startTime = performance.now();

        const onFrame = () => {
            frameCount++;
            if (!video.paused && !video.ended) {
                requestAnimationFrame(onFrame);
            } else {
                const endTime = performance.now();
                const elapsed = (endTime - startTime) / 1000; // 转换为秒
                const calculatedFPS = frameCount / elapsed;
                resolve(calculatedFPS);
            }
        };

        // 开始播放视频并测量帧率
        video.play();
        requestAnimationFrame(onFrame);
    });
}

// checkVideo 方法：检查视频时长和帧率
function checkVideo(file) {
    return new Promise((resolve) => {
        const isVideo = file.type === 'video/mp4';
        if (!isVideo) {
            resolve({
                file,
                success: false,
                message: '您只能上传MP4格式的文件'
            });
            return;
        }

        const videoPlayer = document.createElement('video');

        videoPlayer.addEventListener('loadedmetadata', async () => {
            const duration = videoPlayer.duration; // 视频时长（秒）
            const width = videoPlayer.videoWidth;
            const height = videoPlayer.videoHeight;

            if (duration >= 12) {
                resolve({
                    file,
                    success: false,
                    message: '上传视频时长需要小于12秒'
                });
                return;
            }

            try {
                const fps = await measureFPS(videoPlayer);
                if (fps > 360) {
                    resolve({
                        file,
                        success: false,
                        message: '帧数不能超过360'
                    });
                } else {
                    resolve({
                        file,
                        success: true,
                        width,
                        height,
                        fps
                    });
                }
            } catch (error) {
                resolve({
                    file,
                    success: false,
                    message: '测量帧率失败'
                });
            }
        });

        videoPlayer.addEventListener('error', () => {
            resolve({
                file,
                success: false,
                message: '请上传视频格式的文件'
            });
        });

        const blob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(blob);
        videoPlayer.src = url;
    });
}



    const doUpload = async (file) => {
        try {
            // 校验视频格式（mp4）
            // 时长（<12s）
            const res = await checkVideo(file)
            if (!res.success) throw new Error(res.message)
            const url = await Utils.uploadFile(file)
            if (!url) return false
            const res2 = await API.saveVideoMaterial({
                videoUrl: url,
                width: res.width,
                height: res.height,
            })
            if (res2?.code != 0) throw new Error(res2?.message)
            return url
        } catch (err) {
            // message.warning(err.message)
            return false
        }
    }
    const [totalCount, setTotalCount] = useState(0)
    const totalCountRef = useRef(0)
    const [successCount, setSuccessCount] = useState(0)
    const successCountRef = useRef(0)
    const maxLimitRef = useRef(20)
    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        setUploadLoading(true)
        console.log('===uploadDebounce===')
        if (fileList.length > 20) message.warning('单次批量上传最多20张')
        fileList = fileList.slice(0, 20)
        totalCountRef.current = fileList.length
        setTotalCount(fileList.length)
        setSuccessCount(0)
        successCountRef.current = 0
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i]
            const res = await doUpload(file)
            if (res) {
                successCountRef.current++
                setSuccessCount(pre => pre+1)
            }
        }
        setOpen(false)
        forceUpdate({})
        if (successCountRef.current == 0) {
            message.warning('上传失败，请重新上传符合要求的动作视频')
        } else if (successCountRef.current != totalCountRef.current) {
            const failCount = totalCountRef.current - successCountRef.current
            message.info(`${failCount}张上传失败，请重新上传符合要求的动作视频`)
        } else {
            message.success('上传成功')
        }
        setUploadLoading(false)
    }, 100), [])
    const beforeUpload = async (file, fileList) => {
        if (uploadLoading) return
        uploadDebounce(file, fileList)
        return false
    }
    const handleDrop = async (e) => {
        if (uploadLoading) return
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }
    const onRemove = (item) => {
        PSAIModal.confirm({
            title: '确定删除这个自定义动作视频吗？',
            onOk() {
                API.removeCustomTryOnModel({
                    id: item.id
                }).then(res => {
                    if (res?.code != 0) return message.warning(res?.message)
                    message.success('删除成功')
                    forceUpdate({})
                })
            },
        })
    }

    return (
        <div className={styles.Container}>
            {
                !props.isCustomVideo &&
                <div className={styles.Head}>
                    <p className={styles.Title}>选择动作</p>
                    <div className={styles.VideoFilter}>
                        <Select
                            value={sex}
                            {...optFactory(sexOpts, '性别')}
                            onChange={setSex}
                            tagRender={tagRender}
                        />
                    </div>
                </div>
            }
            <div id="tour_target_action" className={styles.Content}>
            {
                loading ? <div className={styles.empty_wrap}><Spin /></div> :
                (!props.isCustomVideo && showList?.length == 0) ? <div className={styles.empty_wrap}><Empty /></div> : 
                <div className={styles.SelectionItemBox}>
                {
                    props.isCustomVideo &&
                    <Upload.Dragger
                        disabled={uploadLoading}
                        key="upload"
                        className={`${styles.sceneItem} ${styles.uploadWrap}`}
                        accept='video/mp4'
                        showUploadList={false}
                        multiple
                        beforeUpload={beforeUpload}
                        onDrop={handleDrop}
                    >
                        <div
                            className={[
                                styles.addTrigger,
                                props.tryOn && styles.tryOn,
                            ].join(' ')}
                        >
                            <div className={styles.addTriggerContent}>
                                { uploadLoading ? <LoadingOutlined /> : <UploadOutlined /> }
                                <p className={styles.Title}>上传动作视频</p>
                                { uploadLoading && <p className={styles.UploadProgress}>{successCount} / {totalCount}</p> }
                                <p className={styles.Requirement} onClick={onImgRequire}>视频要求</p>
                                {/* <div className={styles.cornerMark}><span>限时免费</span></div> */}
                            </div>
                        </div>
                    </Upload.Dragger>
                }
                {
                    showList.map((item) => (
                        <VideoItem
                            key={item.id}
                            record={item}
                            onSelect={selectVideo}
                            onRemove={onRemove} />
                    ))
                }
                </div>
            }
            </div>
            <PSAIModal
                title={"动作视频要求"}
                open={open}
                maxWidth={850}
                footer={null}
                onCancel={() => setOpen(false)}
            >
                <>
                <div className={styles.UploadExample}>
                    <div>
                        <p className={styles.UploadExampleTitle}>正确示例</p>
                        <div className={styles.UploadExampleList}>
                        {exampleJSON.correct_list.map(item => (
                            <div key={item.value} className={styles.UploadExampleItem}>
                                <div>
                                    <img src={require(`@assets/custom_tryonvideo_example/${item.value}`)} />
                                    <img src={require('@assets/images/accurate.png')} alt="" />
                                </div>
                                <p>{item.label.split('\r\n').map(item => <span key={item}>{item}<br/></span>)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div>
                        <p className={styles.UploadExampleTitle}>错误示例</p>
                        <div className={styles.UploadExampleList}>
                        {exampleJSON.incorrect_list.map(item => (
                            <div key={item.value} className={styles.UploadExampleItem}>
                                <div>
                                    <img src={require(`@assets/custom_tryonvideo_example/${item.value}`)} />
                                    <img src={require('@assets/images/incorrect.png')} alt="" />
                                </div>
                                <p>{item.label.split('\r\n').map(item => <span key={item}>{item}<br/></span>)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
                <div className={styles.UploadRequire}>
                    <p className={styles.UploadRequireTitle}>视频要求</p>
                    <div className={styles.UploadRequireList}>
                        <span>1.时常小于12秒</span>
                        <span>2.视频格式为mp4（其余格式待测试）</span>
                        <span>3.视频比例建议9:16</span>
                        <span>4.视频总帧数小于360</span>
                    </div>
                </div>
                </>
            </PSAIModal>
        </div>
    )
}