import DragCommodity from "./DragCommodity/DragCommodity";
import UploadModelAndCommodity from "../components/UploadModelAndCommodity"
import {useRef, forwardRef, useImperativeHandle, useState, useContext, useCallback} from "react"
import styles from './index.module.scss'
import { Spin, Upload } from "antd";
import { GenerateContext } from "@utils/GenerateContext"
import Utils from "@utils/utils"

const SubCompCommodity = forwardRef((props, ref) => {
    const dragRef = useRef()
    const {
        globaluploadLoading, setGlobaluploadLoading,
        refVersion,
    } = useContext(GenerateContext)

    useImperativeHandle(ref, () => ({
        loadNewEdge: (url) => {
            if (!dragRef.current) return
            dragRef.current.loadNewEdge(url)
        },
    }))

    const reUpload = async (...args) => {
        setGlobaluploadLoading(true)
        try {
            return await props.reUpload(...args)
        } catch {
            setGlobaluploadLoading(false)
        }
    }

    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        console.log('===uploadDebounce===')
        setGlobaluploadLoading(true)
        await props.reUpload(file)
    }, 100), [])

    const handleDrop = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            if (filesAndDirs.length == 0) return false
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }
    
    return (
        props.segmentResult?.length > 0 ?
        <Upload.Dragger
            accept={'image/jpeg,image/png,image/webp,image/avif'}
            name="avatar"
            showUploadList={false}
            className={styles.UploadWrap}
            maxCount={1}
            beforeUpload={()=>false}
            onDrop={handleDrop}
        >
            <div className={styles.Container} onClick={e => e.stopPropagation()}>
                <DragCommodity
                    ref={dragRef}
                    imgRatio={props.imgRatio}
                    imgWidth={props.imgWidth}
                    imgHeight={props.imgHeight}
                    aiInteractiveNpy={props.aiInteractiveNpy}
                    segmentResult={props.segmentResult}
                    dragScale={props.dragScale}
                    onDragChange={props.onDragChange}
                    reUpload={reUpload}
                    deleteOpt={props.deleteOpt}
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onNpyChange={props.onNpyChange}
                />
                {
                    refVersion == 3 &&
                    <div className={styles.Tip_float}>
                        请尽量保持与原商品一致的大小和位置
                    </div>
                }
                {
                    globaluploadLoading &&
                    <div className={styles.Loading}>
                        <Spin size="large"  />
                    </div>
                }
            </div>
        </Upload.Dragger> :
        <UploadModelAndCommodity
            imageType={props.imageType}
            onBeforeUpload={props.onBeforeUpload}
        />
    )
})

export default SubCompCommodity